import React, { createRef, useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AadharPanForm from "../Registrationform/KYCDetailAndCategoryDetails/AadharPanForm";

import { Box, Button, Card, CardContent, CardHeader, Chip, Collapse, Container, FormControlLabel, IconButton, Paper, Radio, RadioGroup, Tooltip, Typography, makeStyles, withStyles } from "@material-ui/core";
import UploadDocuments from "../../SubmitDocumentsPageComponents/UploadDocuments/UploadDocuments";

import { PendingDocIcon, VerifiedDocIcon, VerifiedDocIconGreen } from "../../../atoms/SvgIcons/SvgIcons";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import TimelineIcon from '@material-ui/icons/Timeline';
import { useSelector } from "react-redux";
import { RegistrationStepperSelector } from "../../../../redux/features/registration/registrationStepperSlice";
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ExhibitionDetails from "../Registrationform/ExhibitionDetails";
import PeriodDetails from "../Registrationform/PeriodDetails";

import ContactPersonDetails from "../Registrationform/ContactPersonalDetails";
import OrganizationDetails from "../Registrationform/OrganizationDetails";
import { PmayNonPmaySelector } from "../../../../redux/features/pmayNonPmay/pmayNonPmaySlice";
import MortgagePremisesDetails from "./MortagePremisesDetails";
import LesseeDetails from "./LesseeDetails";
import LiaisonPersonForm from "./LiaisonPersonForm";
import OCForm from "./OCForm";
import BUAForm from "./BUAForm";

import PreviousNOCDetailsForm from "./PreviousNocDetailForm";
import ProcessingFeesDetails from "./ProcessingFeesDetails";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import PaymentForm from "../Registrationform/PaymentForm";
import SubLicenceDetail from "./SubLicenceDetail";

const useStyles = makeStyles((theme) => ({
    card: {
        minWidth: 300,
        border: "1px solid rgba(211,211,211,0.6)",
        margin: theme.spacing(1, 2),

    },
    cardTitle: {
        fontSize: '18px',
        fontWeight: '500',
        fontFamily: 'Poppins',
        lineHeight: '27px',
    },
    sectionNumber: {
        marginRight: theme.spacing(1),
        width: '28px',
        height: '28px',
        padding: theme.spacing(1),
        borderLeftColor: '#00437E',
        borderLeftStyle: 'solid',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
        background: 'rgba(0, 67, 126, 0.06)',
    },
    contentContainer: {
        height: '90%',
        lineHeight: 2,
        width: '100%',
    },
    verifiedBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px 8px 4px 6px",
        gap: "6px",
        borderRadius: "40px",
        background: "linear-gradient(113.08deg, #10BAEF -80.36%, #00A848 124.11%)",
        "& span": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#FFFFFF",
            fontSize: "11px",
            fontWeight: "700",
            width: "100px",
            height: "25px",
        },
    },
    PendingBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px 8px 4px 6px",
        gap: "6px",
        borderRadius: "40px",
        background: "linear-gradient(113.08deg, #efcb10 -80.36%, #8ea800 124.11%)",
        "& span": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#FFFFFF",
            fontSize: "11px",
            fontWeight: "700",
            width: "100px",
            height: "25px",
        },
    },
    progressChip: {
        background: "rgba(101, 112, 125, 0.1)",
        fontWeight: 700,
        color: "#4C5D6C",
        border: '1px solid',
        "&.done": {
            background: "rgba(33, 150, 83, 0.12)",
            color: "#219653",
        },
        "&.pending": {
            background: "#FDF7E5",
            color: "#F27807",
        },
        "&.overdue": {
            color: "#FD000D",
            background: "rgba(235, 87, 87, 0.06)",
        },
        '&.inProgress': {
            color: "#0038C0",
            background: "#EDF2FF",
        }
    },
}));
const CustomTooltip = withStyles({
    tooltip: {
        backgroundColor: "#FFFFFF",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: 11,
        boxShadow: "0px 4px 20px rgba(23, 33, 61, 0.06);",
        borderRadius: "8px",
        border: "1px solid rgba(0, 56, 192, 1)",
    },
    rootm: {
        height: '100%',


    },
    arrow: {
        "&:before": {
            border: "1px solid rgba(0, 56, 192, 1)",
        },
        color: "#FFFFFF",
    },
})(Tooltip);
export default function MortgageForm({ section }) {
    const classes = useStyles();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const step = queryParams.get('step');
    const docId = queryParams.get('docId');
    const history = useHistory();
    const sectionRefs = useRef([]);
    const { isFetchRegStepper,
        isSuccessgetRegStepper,
        getRegStepper,
        getRegActiveStep,
        isErrorgetRegStepper,
        getRegStepperData,
        errorMessagegetRegStepper,
        getRegTotalStep,
        isSuccessRegStepper,
    } = useSelector(RegistrationStepperSelector);
    const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
    const [summary, setSummary] = useState({});
    const [payment, setPayment] = useState({});
    const [document, setDocument] = useState({});
    const [openSection, setOpenSection] = useState(section);
    const [activeStep, setActiveStep] = useState(0);


    useEffect(() => {
        if (step) {
            setOpenSection(step);
        } else setOpenSection(section);
    }, [section, step]);

    useEffect(() => {
        if (step && sectionRefs.current[step - 1]) {
            console.log(sectionRefs);
            sectionRefs.current[step - 1].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'center'
            });
        }
    }, [step, section]);

    useEffect(() => {
        if (isSuccessResSummary) {
            setSummary(summaryData[0]);
            setPayment(summaryData?.PaymentDetails[0]);
            setDocument(summaryData?.DocumentDetails); // Assuming summaryData is an array
        }
    }, [isSuccessResSummary, summaryData]);

    useEffect(() => {
        if (isSuccessgetRegStepper || getRegActiveStep) {
            setActiveStep(getRegActiveStep);
        }
    }, [isSuccessgetRegStepper, getRegActiveStep]);

    useEffect(() => {
        localStorage.setItem('activeStep', activeStep);
    }, [activeStep]);

    const handleToggle = (section) => {
          if (section > activeStep) return;
        setOpenSection((prevOpenSection) => (prevOpenSection === section ? null : section));
        const activeSectionRef = sectionRefs.current[section - 1];
        if (activeSectionRef && activeSectionRef.current) {
            activeSectionRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    };

    useEffect(() => {
        sectionRefs.current = Array(10).fill().map((_, i) => sectionRefs.current[i] || createRef());
    }, []);

    const preventCollapseClosing = (event) => {
        event.stopPropagation();
    };
    const handleButtonClick = () => {
        // Check if summaryData and PaymentDetails are defined and if the first element is not empty
        if (activeStep === 11) {
            // Redirect to dashboard if PaymentDetails[0] is not empty
            history.push("/dashboard");
        } else {
            // Redirect to summary if PaymentDetails[0] is empty or undefined
            history.push("/summary");
        }
    };
    console.log(activeStep, "active")
    return (
        <>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((section) => (
                <Card key={section} className={classes.card} ref={sectionRefs.current[section - 1]} onClick={() => handleToggle(section)}>
                    <CardHeader
                        title={
                            <Typography variant="subtitle1" className={classes.cardTitle}>
                                <Typography variant="span" className={classes.sectionNumber}>{section}</Typography> {getTitle(section)}
                            </Typography>
                        }
                        action={
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                {section < activeStep ? (
                                    <Chip color={'secondary'} className={`${classes.progressChip} done`} icon={<VerifiedDocIconGreen />} label="Completed" />
                                ) : section === activeStep ? (
                                    <Chip color={'secondary'} className={`${classes.progressChip} pending`} icon={<QueryBuilderIcon />} label="In Progress" />
                                ) : (
                                    <Chip color={'secondary'} className={`${classes.progressChip}`} icon={<PendingDocIcon style={{ fontSize: '1.3rem' }} />} label="Pending" />
                                )}
                                {section > activeStep ? (
                                    <CustomTooltip arrow placement="top" title={"Please Complete Previous Step To Continue"}>
                                        <span>
                                            <IconButton size="small" disabled>
                                                <LockTwoToneIcon size='small' />
                                            </IconButton>
                                        </span>
                                    </CustomTooltip>
                                ) : (
                                    <IconButton aria-label="expand" size="small">
                                        {openSection === section ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                )}
                            </Box>
                        }
                    />
                    <Collapse in={openSection == section} timeout="auto" unmountOnExit={true}>
                        <CardContent onClick={preventCollapseClosing}>
                            <Container className={classes.contentContainer}>
                                {getSectionContent(section, activeStep, summary, payment)}
                            </Container>
                        </CardContent>
                    </Collapse>
                </Card>
            ))}
            {activeStep == 11 && (
                <Box display="flex" justifyContent="flex-end" margin={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push("/dashboard")}
                    >
                        Proceed To Dashboard
                    </Button>
                </Box>
            )}
            {activeStep == 10 && (
                <Box display="flex" justifyContent="flex-end" margin={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push("/summary")}
                    >
                        Proceed To Payment
                    </Button>
                </Box>
            )}
        </>
    );
}

function getTitle(section) {
    switch (section) {
        case 1:
            return "Details of the premises proposed to be mortgage";
        case 2:
            return "Lessee Details";
        case 3:
            return "Person authorized for Liaison";
        case 4:
            return "OC/Part OC of the premises to be assign";
        case 5:
            return "Whether Premises is part of original BUA/ Addl. BUA (Sq. Mtrs.)";
        case 6:
            return "Sub Licensee Detail";
        // case 7:
        //     return "Mode of Assignment";
        case 7:
            return "Details of the previous NOC granted (if applicable)";
        case 8:
            return "Upload Required Documents";
        case 9:
            return "Processing Fees Details";

        default:
            return "";
    }
}

function getSectionContent(section, activeStep, summary) {
    switch (section) {
        case 1:
            return <MortgagePremisesDetails docURL={document} data={summary} active={activeStep} />;
        case 2:
            return <LesseeDetails data={summary} docURL={document} active={activeStep} />;
        case 3:
            return <LiaisonPersonForm docURL={document} data={summary} active={activeStep} />;
        case 4:
            return <OCForm data={summary} docURL={document} active={activeStep} />;
        case 5:
            return <BUAForm data={summary} docURL={document} active={activeStep} />;
        case 6:
            return <SubLicenceDetail data={summary} docURL={document} active={activeStep} />
        //<BankDetailsForm data={summary} docURL={document} active={activeStep} />;
        // case 7:
        //     return <ModeOfAssignmentForm data={summary} docURL={document} active={activeStep} />;
        // case 8:
        //     return <PreviousNOCDetailsForm data={summary} docURL={document} active={activeStep} />;
        case 7:
            return <PreviousNOCDetailsForm data={summary} docURL={document} active={activeStep} />;

        case 8:
            return <UploadDocuments data={summary} docURL={document} active={activeStep} />;

        case 9:
            return <ProcessingFeesDetails data={summary} docURL={document} active={activeStep} />;
        case 10:
            return <PaymentForm data={summary} docURL={document} active={activeStep} />;
        //     return <PreviousNOCDetailsForm data={summary} docURL={document} active={activeStep} />;
        default:
            return null;
    }
}
