import React, { useEffect } from 'react';
import { Box, Button, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormControl from '../../../molecules/FormControl/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { applicantSelector } from '../../../../redux/features/applicant/ApplicantSlice';
import { ExhibitionDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';

// Validation schema using Yup
const validationSchema = Yup.object({
  registrationNumber: Yup.string().required('Registration Details is required'),
  eventName: Yup.string().required('Event Name is required'),
  purpose: Yup.string().required('Purpose is required'),
  category: Yup.string().required('Category is required'),
});

const ExhibitionDetails = ({data}) => {
  const {
    isFetchingApplicantAdd,
    isSuccessReqApplicantAdd,
    isErrorApplicantAdd,
    errorMessageAdd,
    isSuccessResApplicantAdd,
    isFetchingApplicantGet,
    isSuccessResApplicantGet,
    applicantData,
    isSuccessResStateCity,
    StateCityData,
    isSuccessResState,
    StateData,
    isSuccessResDistrict,
    DistrictData,
  } = useSelector(applicantSelector);
  const { isFetchingBidder,
    isSuccessBidder,isFetchingExhibition,isSuccessExhibition } = useSelector(PmayNonPmaySelector)


    const dispatch = useDispatch();
    useEffect(() => {
      if (isSuccessExhibition) {
          dispatch(RegistrationStepperSave("4"))
      }
  }, [isSuccessExhibition]);
  const options = [
    { value: '1',  label: "Any Organizations /Institution (All Institutes / Organizations except Govt.Offices/Organization) for any program but excluding Circus. Government - Semi Government, Public Organizations (for non commercial purpose) "},
    { value: '2', label: 'Government Organizations/Institutes' },
    
  ];
 

  return (
    <Formik
      initialValues={{
        registrationNumber: data?.compRegistrationNumber||'',
        eventName: data?.EventName || '',
        purpose: data?.Purpose||'',
        category: data?.ApplicantCatId || '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        // Handle form submission
        console.log(values);
        setSubmitting(false);
        dispatch(ExhibitionDetailed(values));
      }}

      
    >

      
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  control="input"
                  variant="outlined"
                  label="Registration Details"
                  placeholder="Registration Details"
                  name="registrationNumber"
                  type="text"
                  id="registrationNumber"
                  required
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  control="input"
                  variant="outlined"
                  label="Event Name"
                  placeholder="Event Name"
                  name="eventName"
                  type="text"
                  id="eventName"
                  required
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  control="input"
                  variant="outlined"
                  label="Purpose"
                  placeholder="Purpose"
                  name="purpose"
                  type="text"
                  id="purpose"
                  required
                  margin="dense"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <FormControl
                  control="input"
                  variant="outlined"
                  label="Category"
                  placeholder="Category"
                  name="category"
                  type="text"
                  id="category"
                  required
                  margin="dense"
                /> */}
                 <FormControl
                        control="selectbox"
                        variant="outlined"
                        name="category"
                        label="Category"
                        options={options}
                        margin='dense'
                        required
                      />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
              
              
                type='submit'
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ExhibitionDetails;
