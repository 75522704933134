// import React, { useEffect } from 'react';
// import {
//     Box,
//     Typography,
//     FormControl,
//     RadioGroup,
//     FormControlLabel,
//     Radio,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Paper,
//     Divider,
//     FormLabel,
//     Grid,
//     Button,
//     Checkbox,
//     makeStyles,
//     withStyles,
//     CircularProgress,
// } from '@material-ui/core';
// import { useFormik } from 'formik';
// import AlertBox from '../../../atoms/AlertBox/AlertBox';
// import { RegistrationStepperSave, RegistrationStepperSelector } from '../../../../redux/features/registration/registrationStepperSlice';
// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import { PmayNonPmaySelector, PostFactoApproval } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';

// const useStyles = makeStyles({
//     table: {
//         minWidth: 700,
//     },
//     rightAlign: {
//         textAlign: 'right',
//     },
// });

// const StyledTableCell = withStyles((theme) => ({
//     head: {
//         backgroundColor: '#15489f',
//         color: theme.palette.common.white,
//         fontSize: 12,
//     },
//     body: {
//         fontSize: 12,
//     },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.action.hover,
//         },
//     },
// }))(TableRow);

// const ProcessingFeesDetails = (props) => {
//     const { active, data } = props;
//     const { isSuccessRegStepper } = useSelector(RegistrationStepperSelector);
//     const { isFetchingPostFactoDetail, isSuccessPostFactoDetail } = useSelector(PmayNonPmaySelector);
//     const history = useHistory();
//     const dispatch = useDispatch();
//     const classes = useStyles();

//     const formik = useFormik({
//         initialValues: {
//             post_facto_approval: data?.post_facto_approval || 'no',
//             processing_fee: 25000.00,
//             processing_fee_gst: 4500.00,
//             assignment_charges: 35000.00,
//             penalty: 10000.00,
//             penalty_gst: 1800.00,
//             terms_and_conditions: data?.terms_and_conditions || false,
//         },
//         onSubmit: (values) => {
//             console.log(values);
//             dispatch(PostFactoApproval(values));
//         },
//     });

//     useEffect(() => {
//         if (isSuccessPostFactoDetail) {
//             dispatch(RegistrationStepperSave('11'));
//         }
//     }, [isSuccessPostFactoDetail]);

//     useEffect(() => {
//         if (isSuccessRegStepper) {
//             history.push('/summary');
//         }
//     }, [isSuccessRegStepper, history]);

//     const getCurrentDate = () => {
//         const now = new Date();
//         const day = String(now.getDate()).padStart(2, '0');
//         const month = String(now.getMonth() + 1).padStart(2, '0');
//         const year = now.getFullYear();
//         return `${day}-${month}-${year}`;
//     };

//     const calculateTotalAmount = () => {
//         let total = formik.values.processing_fee + formik.values.processing_fee_gst + formik.values.assignment_charges;
//         if (formik.values.post_facto_approval === 'yes') {
//             total += formik.values.penalty + formik.values.penalty_gst;
//         }
//         return total.toFixed(2);
//     };

//     return (
//         <Box>
//             <form onSubmit={formik.handleSubmit}>
//                 <Grid item xs={12}>
//                     <FormControl component="fieldset">
//                         <FormLabel component="legend">
//                             Is This Application For Post Facto Approval?
//                         </FormLabel>
//                         <RadioGroup
//                             row
//                             aria-label="post_facto_approval"
//                             name="post_facto_approval"
//                             value={formik.values.post_facto_approval}
//                             onChange={formik.handleChange}
//                         >
//                             <FormControlLabel value="yes" control={<Radio />} label="Yes" />
//                             <FormControlLabel value="no" control={<Radio />} label="No" />
//                         </RadioGroup>
//                     </FormControl>
//                 </Grid>
//                 {formik.values.post_facto_approval === 'yes' && (
//                     <AlertBox severity="error">
//                         <Typography variant="body1" color="error">
//                             10% + GST Penalty will be Applicable For Post Facto Approval
//                         </Typography>
//                     </AlertBox>
//                 )}
//                 <Box display="flex" justifyContent="space-between" alignItems="center">
//                     <Typography style={{ fontWeight: 600 }}>Payment Details</Typography>
//                     <Typography style={{ fontWeight: 600 }}>Date: {getCurrentDate()}</Typography>
//                 </Box>
//                 <Divider />
//                 <TableContainer component={Paper}>
//                     <Table className={classes.table} aria-label="customized table">
//                         <TableHead>
//                             <StyledTableRow>
//                                 <StyledTableCell>Qty</StyledTableCell>
//                                 <StyledTableCell>Fee Type</StyledTableCell>
//                                 <StyledTableCell>Serial #</StyledTableCell>
//                                 <StyledTableCell>Description</StyledTableCell>
//                                 <StyledTableCell className={classes.rightAlign}>Subtotal</StyledTableCell>
//                             </StyledTableRow>
//                         </TableHead>
//                         <TableBody>
//                             <StyledTableRow>
//                                 <StyledTableCell>1</StyledTableCell>
//                                 <StyledTableCell>Processing Fee</StyledTableCell>
//                                 <StyledTableCell>455-981-221</StyledTableCell>
//                                 <StyledTableCell>Processing Fee Details</StyledTableCell>
//                                 <StyledTableCell className={classes.rightAlign}>₹ {formik.values.processing_fee.toFixed(2)}</StyledTableCell>
//                             </StyledTableRow>
//                             <StyledTableRow>
//                                 <StyledTableCell>2</StyledTableCell>
//                                 <StyledTableCell>Processing Fee GST</StyledTableCell>
//                                 <StyledTableCell>455-981-222</StyledTableCell>
//                                 <StyledTableCell>Processing Fee - 18% GST</StyledTableCell>
//                                 <StyledTableCell className={classes.rightAlign}>₹ {formik.values.processing_fee_gst.toFixed(2)}</StyledTableCell>
//                             </StyledTableRow>
//                             <StyledTableRow>
//                                 <StyledTableCell>3</StyledTableCell>
//                                 <StyledTableCell>Assignment Charges</StyledTableCell>
//                                 <StyledTableCell>735-845-642</StyledTableCell>
//                                 <StyledTableCell>Stamp Duty 10% on Document Register Amount</StyledTableCell>
//                                 <StyledTableCell className={classes.rightAlign}>₹ {formik.values.assignment_charges.toFixed(2)}</StyledTableCell>
//                             </StyledTableRow>
//                             {formik.values.post_facto_approval === 'yes' && (
//                                 <>
//                                     <StyledTableRow>
//                                         <StyledTableCell>4</StyledTableCell>
//                                         <StyledTableCell>Penalty</StyledTableCell>
//                                         <StyledTableCell>422-568-642</StyledTableCell>
//                                         <StyledTableCell>Post Facto Consent</StyledTableCell>
//                                         <StyledTableCell className={classes.rightAlign}>₹ {formik.values.penalty.toFixed(2)}</StyledTableCell>
//                                     </StyledTableRow>
//                                     <StyledTableRow>
//                                         <StyledTableCell>5</StyledTableCell>
//                                         <StyledTableCell>Penalty GST</StyledTableCell>
//                                         <StyledTableCell>422-568-643</StyledTableCell>
//                                         <StyledTableCell>Post Facto Consent - 18% GST</StyledTableCell>
//                                         <StyledTableCell className={classes.rightAlign}>₹ {formik.values.penalty_gst.toFixed(2)}</StyledTableCell>
//                                     </StyledTableRow>
//                                 </>
//                             )}
//                             <StyledTableRow>
//                                 <StyledTableCell colSpan={4}>
//                                     <Typography style={{ fontWeight: 600 }}>Total:</Typography>
//                                 </StyledTableCell>
//                                 <StyledTableCell className={classes.rightAlign}>
//                                     <Typography style={{ fontWeight: 600 }}>₹ {calculateTotalAmount()}</Typography>
//                                 </StyledTableCell>
//                             </StyledTableRow>
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//                 <Divider />
//                 {active === 10 && (
//                     <Box margin={2}>
//                         <FormControlLabel
//                             control={
//                                 <Checkbox
//                                     name="terms_and_conditions"
//                                     color="primary"
//                                     checked={formik.values.terms_and_conditions}
//                                     onChange={formik.handleChange}
//                                 />
//                             }
//                             label="I agree to the terms and conditions"
//                         />
//                         <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
//                             <Button
//                                 type="submit"
//                                 variant="contained"
//                                 color="primary"
//                                 size="large"
//                                 disabled={isFetchingPostFactoDetail || !formik.values.terms_and_conditions}
//                             >
//                                 {isFetchingPostFactoDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
//                                 {isFetchingPostFactoDetail ? 'Saving...' : 'Save & Continue'}
//                             </Button>
//                         </Grid>
//                     </Box>
//                 )}
//             </form>
//         </Box>
//     );
// };

// export default ProcessingFeesDetails;


import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import {
    Box,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Divider,
    FormLabel,
    Grid,
    Button,
    Checkbox,
    CircularProgress,
    TextField,
    makeStyles,
    withStyles
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UploadedKyc from "../../../../assets/UploadedKyc.svg";
import { FileUploadIcon } from '../../../atoms/SvgIcons/SvgIcons';
import AlertBox from '../../../atoms/AlertBox/AlertBox';
import { RegistrationStepperSave, RegistrationStepperSelector } from '../../../../redux/features/registration/registrationStepperSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PmayNonPmaySelector, PostFactoApproval } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { clearImageUrl, fileDataUpload, fileUploadSelector } from '../../../../redux/features/file/FileUploadSlice';
import { SupportedFormats } from '../../../../utils/Common';
import { useTranslation } from 'react-i18next';
import { initialPagesStyles } from '../../InitialPagesComponents/InitialPages.styles';

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    rightAlign: {
        textAlign: 'right',
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#15489f',
        color: theme.palette.common.white,
        fontSize: 12,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ProcessingFeesDetails = (props) => {
    const { active, data } = props;
    const { t } = useTranslation("InitialPageTrans");
    const { isSuccessRegStepper } = useSelector(RegistrationStepperSelector);
    const { isFetchingPostFactoDetail, isSuccessPostFactoDetail } = useSelector(PmayNonPmaySelector);
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const classed = initialPagesStyles();
    const [uploadingField, setUploadingField] = useState('');
    const [isUploaded, setUploaded] = useState(false);

    const fileInputRefA = useRef(null);
    const [isImageA, setIsImageA] = useState(false);
    const [approvedPlanAUrl, setApprovedPlanAUrl] = useState('');
    const [approvalPlanAPreview, setApprovalPlanAPreview] = useState(null);
    const {
        isFileFetching,
        isFileSuccess,
        imageUrl,
        isFileError,
        fileErrorMessage,
    } = useSelector(fileUploadSelector);
    const formik = useFormik({
        initialValues: {
            post_facto_approval: data?.post_facto_approval || 'no',
            stamp_duty_paid: 'no',
            stamp_duty_receipt: null,
            processing_fee: 25000.00,
            processing_fee_gst: 4500.00,
            assignment_charges: 35000.00,
            penalty: 10000.00,
            penalty_gst: 1800.00,
            stamp_duty_charges:'',
            terms_and_conditions: data?.terms_and_conditions || false,
        },
        validationSchema: Yup.object().shape({
            // stamp_duty_receipt: Yup.mixed().when('stamp_duty_paid', {
            //     is: 'yes',
            //     then: Yup.mixed().required('Stamp duty receipt is required if stamp duty is paid'),
            // }),
            terms_and_conditions: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
        }),
        onSubmit: (values) => {

            const data = {
                ...values,
                // stamp_duty_receipt: approvedPlanAUrl,
                stamp_duty_charges: formik.values.stamp_duty_paid === "no" ? '2500' : "",

            }

            console.log(data, "data");
            dispatch(PostFactoApproval(values));
        },
    });

    function hasImageExtension(url) {
        if (!url) {
            return false;
        }
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.tiff'];
        return imageExtensions.some(extension => url.toLowerCase().endsWith(extension));
    }

    const onReUpload = (fieldName) => {
        if (fieldName === 'stamp_duty_receipt' && fileInputRefA.current) {
            fileInputRefA.current.click();
        }
    };

    useEffect(() => {
        if (isFileSuccess && imageUrl) {
            formik.setFieldValue("stamp_duty_receipt", imageUrl);
            setApprovedPlanAUrl(imageUrl);
            if (uploadingField === 'stamp_duty_receipt') {
                setApprovedPlanAUrl(imageUrl);
                
                dispatch(clearImageUrl());
            }
            setUploadingField('');
        }
    }, [isFileSuccess, imageUrl, uploadingField, dispatch]);
   
    useEffect(() => {
        if (isFileSuccess) {
            setUploaded(true);
        }
    }, [isFileSuccess]);

    const handleFileChange = (event, fieldName) => {
        const file = event.currentTarget.files[0];
        if (file) {
            setUploadingField(fieldName);
            //formik.setFieldValue("stamp_duty_receipt", file.name);
            const data = { doc: file, docName: "stamp_duty_receipt" };
            dispatch(fileDataUpload(data));
            if (fieldName === 'stamp_duty_receipt') {
                if (SupportedFormats.DocsFormats.includes(file.type)) {
                    switch (file.type) {
                        case "image/jpeg":
                        case "image/png":
                            setIsImageA(true);
                            break;
                        case "application/pdf":
                        default:
                            setIsImageA(false);
                    }
                }
                setApprovalPlanAPreview(URL.createObjectURL(file));
            }
        }
    };

    useEffect(() => {
        if (data?.stamp_duty_receipt) {
            setUploaded(true);
        }
    }, [data]);
    useEffect(() => {
        if (isSuccessPostFactoDetail) {
            dispatch(RegistrationStepperSave('10'));
        }
    }, [isSuccessPostFactoDetail]);

    useEffect(() => {
        if (isSuccessRegStepper) {
            history.push('/summary');
        }
    }, [isSuccessRegStepper, history]);

    const getCurrentDate = useCallback(() => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        return `${day}-${month}-${year}`;
    }, []);

    const calculateTotalAmount = useMemo(() => {
        let total = formik.values.processing_fee + formik.values.processing_fee_gst + formik.values.assignment_charges;
        if (formik.values.post_facto_approval === 'yes') {
            total += formik.values.penalty + formik.values.penalty_gst;
        }
        if (formik.values.stamp_duty_paid === 'no') {
            total += formik.values.processing_fee * 0.1; // Add 10% stamp duty
        }
        return total.toFixed(2);
    }, [formik.values]);

    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            Is This Application For Post Facto Approval?
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-label="post_facto_approval"
                            name="post_facto_approval"
                            value={formik.values.post_facto_approval}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {formik.values.post_facto_approval === 'yes' && (
                    <AlertBox severity="error">
                        <Typography variant="body1" color="error">
                            10% + GST Penalty will be Applicable For Post Facto Approval
                        </Typography>
                    </AlertBox>
                )}

                {/* Stamp Duty Section */}
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            Has Stamp Duty been Paid?
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-label="stamp_duty_paid"
                            name="stamp_duty_paid"
                            value={formik.values.stamp_duty_paid}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {formik.values.stamp_duty_paid === 'no' && (
                    <AlertBox severity="error">
                        <Typography variant="body1" color="error">
                            10% + Stamp Duty will be Applicable
                        </Typography>
                    </AlertBox>
                )}
                {formik.values.stamp_duty_paid === 'yes' && (
                    <Box marginTop={2}>

                        <Grid item xs={6}>
                            <input
                                ref={fileInputRefA}
                                style={{ display: 'none' }}
                                type="file"
                                accept="image/*, application/pdf"
                                id="stamp_duty_receipt"
                                name="stamp_duty_receipt"
                                onChange={(event) => handleFileChange(event, 'stamp_duty_receipt')}
                            />
                            <TextField
                                fullWidth
                                label='Upload Stamp Duty Receipt'
                                variant="outlined"
                                margin="dense"
                                disabled
                                value={formik.values.stamp_duty_receipt}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            onClick={() => fileInputRefA.current.click()}
                                        >
                                            Browse
                                        </Button>
                                    ),
                                }}
                            />
                            {approvalPlanAPreview && (
                                <Box>
                                    <Typography className={classed.kycCaption} variant="subtitle1">
                                        Preview Stamp Duty Receipt
                                    </Typography>
                                    {isImageA ? (
                                        <Box className={classed.panCardPreviewCard}>
                                            <img
                                                className={classed.panPreviewImg}
                                                src={approvalPlanAPreview}
                                                alt="uploaded successfully"
                                            />
                                        </Box>
                                    ) : (
                                        <Box textAlign="center" className={classed.panCardPreviewCard}>
                                            <img className={classed.panPreviewImg} src={UploadedKyc} alt="uploaded successfully" />
                                            <Typography className={classed.kycCaption}>
                                                {t("kycBank.formControl.upload.success")}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box textAlign="left" marginTop={2}>
                                        <Typography className={classed.kycCaption} variant="subtitle1">
                                            Uploaded Stamp Duty Receipt
                                        </Typography>
                                    </Box>
                                    <Box className={classed.fileViewArea}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs="auto">
                                                <FileUploadIcon color="primary" />
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <Typography variant="body2" className={classed.fileUrlPreview}>
                                                    {approvedPlanAUrl || "--"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Button
                                                    variant="text"
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => onReUpload('stamp_duty_receipt')}
                                                >
                                                    {t("kycBank.formControl.upload.reupload")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                    </Box>
                )}

                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: 20 }}>
                    <Typography style={{ fontWeight: 600 }}>Payment Details</Typography>
                    <Typography style={{ fontWeight: 600 }}>Date: {getCurrentDate()}</Typography>
                </Box>
                <Divider />
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Qty</StyledTableCell>
                                <StyledTableCell>Fee Type</StyledTableCell>
                                <StyledTableCell>Serial #</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>Subtotal</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell>1</StyledTableCell>
                                <StyledTableCell>Processing Fee</StyledTableCell>
                                <StyledTableCell>455-981-221</StyledTableCell>
                                <StyledTableCell>Processing Fee Details</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formik.values.processing_fee.toFixed(2)}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell>2</StyledTableCell>
                                <StyledTableCell>Processing Fee GST</StyledTableCell>
                                <StyledTableCell>455-981-222</StyledTableCell>
                                <StyledTableCell>Processing Fee - 18% GST</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formik.values.processing_fee_gst.toFixed(2)}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell>3</StyledTableCell>
                                <StyledTableCell>Assignment Charges</StyledTableCell>
                                <StyledTableCell>735-845-642</StyledTableCell>
                                <StyledTableCell>Stamp Duty 10% on Document Register Amount</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formik.values.assignment_charges.toFixed(2)}</StyledTableCell>
                            </StyledTableRow>
                            {formik.values.post_facto_approval === 'yes' && (
                                <>
                                    <StyledTableRow>
                                        <StyledTableCell>4</StyledTableCell>
                                        <StyledTableCell>Penalty</StyledTableCell>
                                        <StyledTableCell>422-568-642</StyledTableCell>
                                        <StyledTableCell>Post Facto Approval Penalty</StyledTableCell>
                                        <StyledTableCell className={classes.rightAlign}>₹ {formik.values.penalty.toFixed(2)}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell>5</StyledTableCell>
                                        <StyledTableCell>Penalty GST</StyledTableCell>
                                        <StyledTableCell>422-568-643</StyledTableCell>
                                        <StyledTableCell>Penalty - 18% GST</StyledTableCell>
                                        <StyledTableCell className={classes.rightAlign}>₹ {formik.values.penalty_gst.toFixed(2)}</StyledTableCell>
                                    </StyledTableRow>
                                </>
                            )}
                            {formik.values.stamp_duty_paid === 'no' && (
                                <StyledTableRow>
                                    <StyledTableCell>6</StyledTableCell>
                                    <StyledTableCell>Stamp Duty</StyledTableCell>
                                    <StyledTableCell>122-568-643</StyledTableCell>
                                    <StyledTableCell>10% of Processing Fee as Stamp Duty</StyledTableCell>
                                    <StyledTableCell className={classes.rightAlign}>₹ {(formik.values.processing_fee * 0.1).toFixed(2)}</StyledTableCell>
                                </StyledTableRow>
                            )}
                            <StyledTableRow>
                                <StyledTableCell colSpan={4} className={classes.rightAlign}>Total Amount</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {calculateTotalAmount}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box marginTop={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="terms_and_conditions"
                                color="primary"
                                checked={formik.values.terms_and_conditions}
                                onChange={formik.handleChange}
                            />
                        }
                        label="I have read and agree to the Terms and Conditions"
                    />
                </Box>

                <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isFetchingPostFactoDetail || !formik.values.terms_and_conditions}
                    >
                        {isFetchingPostFactoDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
                        {isFetchingPostFactoDetail ? 'Saving...' : 'Save & Continue'}
                    </Button>
                </Grid>
            </form>
        </Box>
    );
};

export default ProcessingFeesDetails;
