import React from "react";

import Layout from "../Layout/Layout";
import SummaryDetails from "../../organisms/RegistrationComponents/Registrationform/Summary";
import PaymentForm from "../../organisms/RegistrationComponents/Registrationform/PaymentForm";



const SummaryPage = () => {
  return (
    <Layout isStepper={false} noScrollIs={true} >
      {/* <Dashboard /> */}
      {/* <SummaryDetails/> */}
      
      <PaymentForm/>
    </Layout>
  );
};

export default SummaryPage;