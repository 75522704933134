import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
  Dialog,
  Box,
  DialogActions,
  Grid,
  DialogTitle,
  DialogContent,
  Paper,
  Divider,
} from "@material-ui/core";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { styled } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LiveBid from "./LiveBid";
import { DashboardStyle } from "../../DashboardTabs.style";
import { EauctionSelector, clearEauctionSingleProjectData, getSingleAuctionProject } from "../../../../../../redux/features/eauction/eauctionSlice";

import DataTableBox from "../../../../../atoms/DataTableBox/DataTableBox";
import { numberWithCommas } from "../../../../../../utils/util";
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { LiveBidSelector, getRecentBids } from "../../../../../../redux/features/eauction/liveBidSlice";


const BiddingDetail = ({ onTabChange }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const { t } = useTranslation("ProfilePageTrans");
  const classes = DashboardStyle();
  const projectData = data;

  const [recentBids, setRecentBids] = useState();
  const { isFetchingRecentBids,
    isSuccessRecentBids,
    isErrorRecentBids,
    recentBidsData,
    errorMessageRecentBids,

    isFetchingPlaceBid,
    isSuccessPlaceBid,
    isErrorPlaceBid,
    placeBidData,
    errorMessagePlaceBid } = useSelector(LiveBidSelector);


  useEffect(() => {
    dispatch(getSingleAuctionProject());
    return () => {
      dispatch(clearEauctionSingleProjectData());
    };
  }, []);
  const {
    //MyProject Listing Variable state
    isFetchingSingleProject,
    isSuccessSingleProject,
    isErrorSingleProject,
    singleprojectData,
    errorMessageSingleProject,
  } = useSelector(EauctionSelector);
  useEffect(() => {
    if (projectData) {
      dispatch(getRecentBids(projectData.id))
    }
  }, [projectData]);

  useEffect(() => {
    if (isSuccessRecentBids && recentBidsData) {
      setRecentBids(recentBidsData.lastTopTenBids)
    }
  }, [isSuccessRecentBids, recentBidsData])

  useEffect(() => {
    if (isSuccessSingleProject && singleprojectData) {
      setData(singleprojectData);
    }
  }, [isSuccessSingleProject, singleprojectData]);

  {console.log(projectData.FinBidAmount,"sdsdsds")}

  return (
    <Grid container justifyContent="space-around">
      <Grid
        xs={5}
        item
        className={classes.detailBox}
      >
        <Grid
          container
          justifyContent="flex-start"
        >
          <Typography className={classes.boxTitle}>
            Tender Detail
          </Typography>
        </Grid>
        <DataTableBox
          label={"Scheme Brief"}
          value={projectData.eventID}
        />
        <DataTableBox
          label={"Shop no."}
          value={projectData.shopNo}
        />
        <DataTableBox
          label={"Auto Increment Factor"}
          value={`₹ ${numberWithCommas(projectData.auctionIncrementValue)}`}
        />
        {/* <Grid
          container
          justifyContent="flex-start"
        >
          <Typography className={classes.boxTitle}>
            e-Auction schedule date
          </Typography>
        </Grid> */}
        <DataTableBox
          label={"Auction Start Date"}
          value={projectData.eauctionStartDate}
        />
        <DataTableBox
          label={"Auction End Date"}
          value={projectData.eauctionEndDate}
        />
        <DataTableBox
          label={"Result Opening"}
          value={projectData.eauctionResultOpenDate}
        />
        <DataTableBox
          label={"Your Close Bid"}
          // value={`₹ ${numberWithCommas(projectData.closeBid)}`}
          value={`₹ ${projectData.FinBidAmount}`}
        />
        <DataTableBox
          label={"Base Price"}
          value={`₹ ${numberWithCommas(projectData.auctionBasePrice)}`}
        />
      </Grid>
      <Divider orientation="vertical" flexItem />

      <Grid xs={5} container alignItems="flex-end">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={240}
            data={recentBids}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="CreatedAt" />
            {/* <YAxis /> */}
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="BidValue" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item xs={12}>
        <LiveBid projectData={projectData} />
      </Grid>
    </Grid>
  );
};

export default BiddingDetail;
