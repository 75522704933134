// import React, { useEffect, useState } from 'react';
// import { useFormik } from 'formik';
// import { withStyles, makeStyles } from '@material-ui/core/styles';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton } from '@material-ui/core';
// import TextField from '@mui/material/TextField';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import DeleteIcon from '@material-ui/icons/Delete';
// import { useDispatch, useSelector } from 'react-redux';
// import { OCFormDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
// import { clearImageUrl, fileDataUpload, fileUploadSelector } from '../../../../redux/features/file/FileUploadSlice';
// import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';

// const initialValues = {
//     ocDetails: [
//         { date: '', totalBUA: '', balanceBUA: '', particulars: '', approvedPlan: null }
//     ]
// };

// const StyledTableCell = withStyles((theme) => ({
//     head: {
//         backgroundColor: theme.palette.common.black,
//         color: theme.palette.common.white,
//     },
//     body: {
//         fontSize: 14,
//     },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.action.hover,
//         },
//     },
// }))(TableRow);

// const useStyles = makeStyles({
//     table: {
//         minWidth: 700,
//     },
// });

// const OCForm = (props) => {
//     const { active, data } = props;
//     const classes = useStyles();
//     const dispatch = useDispatch();
//     const [currentFileIndex, setCurrentFileIndex] = useState(null);
//     const { isFileFetching, isFileSuccess, imageUrl } = useSelector(fileUploadSelector);
//     const { isSuccessOCFormDetail } = useSelector(PmayNonPmaySelector);

//     const initialValues = data?.ocDetails ? JSON.parse(data.ocDetails) : { ocDetails: initialValues.ocDetails };

//     const formik = useFormik({
//         initialValues,
//         onSubmit: values => {
//             const formData = {
//                 ocDetails: values.ocDetails.map((ocDetail) => ({
//                     date: ocDetail.date,
//                     totalBUA: ocDetail.totalBUA,
//                     balanceBUA: ocDetail.balanceBUA,
//                     particulars: ocDetail.particulars,
//                     approvedPlan: ocDetail.approvedPlan,
//                 })),
//             };
//             console.log(JSON.stringify({ ocDetails: formData }));
//             dispatch(OCFormDetailed(JSON.stringify({ ocDetails: formData })));
//         },
//     });

//     useEffect(() => {
//         if (isSuccessOCFormDetail) {
//             dispatch(clearImageUrl());
//             dispatch(RegistrationStepperSave("5"));
//         }
//     }, [isSuccessOCFormDetail, dispatch]);

//     useEffect(() => {
//         if (isFileSuccess && imageUrl !== '') {
//             const newOCDetails = [...formik.values.ocDetails];
//             if (currentFileIndex !== null) {
//                 newOCDetails[currentFileIndex].approvedPlan = imageUrl;
//                 formik.setFieldValue('ocDetails', newOCDetails);
//                 setCurrentFileIndex(null);
//                 dispatch(clearImageUrl());
//             }
//         }
//     }, [isFileSuccess, imageUrl, currentFileIndex, formik, dispatch]);

//     const handleInputChange = (index, field, value) => {
//         const newOCDetails = [...formik.values.ocDetails];
//         newOCDetails[index][field] = value;
//         formik.setFieldValue('ocDetails', newOCDetails);
//     };

//     const handleFileChange = (index, event) => {
//         const file = event.currentTarget.files[0];
//         if (file) {
//             const newOCDetails = [...formik.values.ocDetails];
//             newOCDetails[index].approvedPlanUrl = URL.createObjectURL(file);
//             formik.setFieldValue('ocDetails', newOCDetails);
//             const sendData = {
//                 doc: file,
//                 docName: `Approved Plan ${index + 1}`,
//             };
//             setCurrentFileIndex(index);
//             dispatch(fileDataUpload(sendData));
//         }
//     };

//     const addMoreOC = () => {
//         const newOC = { date: '', totalBUA: '', balanceBUA: '', particulars: '', approvedPlan: null };
//         formik.setFieldValue('ocDetails', [...formik.values.ocDetails, newOC]);
//     };

//     const removeOC = (index) => {
//         const newOCDetails = [...formik.values.ocDetails];
//         newOCDetails.splice(index, 1);
//         formik.setFieldValue('ocDetails', newOCDetails);
//     };

//     return (
//         <form onSubmit={formik.handleSubmit}>
//             <TableContainer component={Paper}>
//                 <Table className={classes.table} aria-label="oc details table">
//                     <TableHead>
//                         <TableRow>
//                             <StyledTableCell>Date of OC - Part OC</StyledTableCell>
//                             <StyledTableCell>Total BUA</StyledTableCell>
//                             <StyledTableCell>Balance BUA</StyledTableCell>
//                             <StyledTableCell>Particulars of Premises</StyledTableCell>
//                             <StyledTableCell>Upload Copy of Approved Plan</StyledTableCell>
//                             <StyledTableCell>Actions</StyledTableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {formik.values.ocDetails.map((ocDetail, index) => (
//                             <StyledTableRow key={`oc_${index}`}>
//                                 <StyledTableCell>
//                                     <TextField
//                                         fullWidth
//                                         id={`date_${index}`}
//                                         name={`ocDetails[${index}].date`}
//                                         value={ocDetail.date}
//                                         onChange={(e) => handleInputChange(index, 'date', e.target.value)}
//                                         variant="outlined"
//                                         margin="dense"
//                                     />
//                                 </StyledTableCell>
//                                 <StyledTableCell>
//                                     <TextField
//                                         fullWidth
//                                         id={`totalBUA_${index}`}
//                                         name={`ocDetails[${index}].totalBUA`}
//                                         value={ocDetail.totalBUA}
//                                         onChange={(e) => handleInputChange(index, 'totalBUA', e.target.value)}
//                                         variant="outlined"
//                                         margin="dense"
//                                     />
//                                 </StyledTableCell>
//                                 <StyledTableCell>
//                                     <TextField
//                                         fullWidth
//                                         id={`balanceBUA_${index}`}
//                                         name={`ocDetails[${index}].balanceBUA`}
//                                         value={ocDetail.balanceBUA}
//                                         onChange={(e) => handleInputChange(index, 'balanceBUA', e.target.value)}
//                                         variant="outlined"
//                                         margin="dense"
//                                     />
//                                 </StyledTableCell>
//                                 <StyledTableCell>
//                                     <TextField
//                                         fullWidth
//                                         id={`particulars_${index}`}
//                                         name={`ocDetails[${index}].particulars`}
//                                         value={ocDetail.particulars}
//                                         onChange={(e) => handleInputChange(index, 'particulars', e.target.value)}
//                                         variant="outlined"
//                                         margin="dense"
//                                     />
//                                 </StyledTableCell>
//                                 <StyledTableCell>
//                                     <input
//                                         accept="image/*, application/pdf"
//                                         id={`approvedPlan_${index}`}
//                                         name={`ocDetails[${index}].approvedPlan`}
//                                         type="file"
//                                         onChange={(e) => handleFileChange(index, e)}
//                                         style={{ display: 'none' }}
//                                     />
//                                     <label htmlFor={`approvedPlan_${index}`}>
//                                         <Button
//                                             variant="contained"
//                                             component="span"
//                                             color="primary"
//                                         >
//                                             Upload
//                                         </Button>
//                                     </label>
//                                     {ocDetail.approvedPlanUrl  && (
//                                         <div>
//                                             {ocDetail.approvedPlanUrl.endsWith('.pdf') ? (
//                                                 <a href={ocDetail.approvedPlanUrl} target="_blank" rel="noopener noreferrer">View PDF</a>
//                                             ) : (
//                                                 <img
//                                                     src={ocDetail.approvedPlanUrl}
//                                                     alt={`Approved Plan ${index}`}
//                                                     style={{ maxWidth: '100px', marginTop: '10px' }}
//                                                 />
//                                             )}
//                                         </div>
//                                     )}
//                                 </StyledTableCell>
//                                 <StyledTableCell>
//                                     <IconButton
//                                         onClick={() => removeOC(index)}
//                                         aria-label="delete"
//                                         color="secondary"
//                                     >
//                                         <DeleteIcon />
//                                     </IconButton>
//                                 </StyledTableCell>
//                             </StyledTableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </TableContainer>

//             <Button
//                 type="button"
//                 variant="contained"
//                 color="primary"
//                 onClick={addMoreOC}
//                 style={{ marginTop: '10px' }}
//             >
//                 <AddCircleIcon style={{ marginRight: '5px' }} />
//                 Add More
//             </Button>

//             <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px', marginLeft: '10px' }}>
//                 Submit
//             </Button>
//         </form>
//     );
// };

// export default OCForm;


import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Grid, CircularProgress } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { OCFormDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { clearImageUrl, fileDataUpload, fileUploadSelector } from '../../../../redux/features/file/FileUploadSlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
import AlertBox from '../../../atoms/AlertBox/AlertBox';
import { getSummaryDetails,  } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';

const defaultValues = {
    ocDetails: [
        { date: '', totalBUA: '', balanceBUA: '', particulars: '', approvedPlan: null, approvedPlanUrl: '' }
    ]
};

const validationSchema = Yup.object().shape({
    ocDetails: Yup.array().of(
        Yup.object().shape({
            date: Yup.date().required('Date is required'),
            totalBUA: Yup.number().required('Total BUA is required'),
            balanceBUA: Yup.number().required('Balance BUA is required'),
            particulars: Yup.string().required('Particulars are required'),
            approvedPlan: Yup.mixed().required('Approved plan is required')
        })
    )
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const OCForm = (props) => {
    const { active, data } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [currentFileIndex, setCurrentFileIndex] = useState(null);
   
    const { isSuccessOCFormDetail,
        isErrorOCFormDetail,
        errorMsgOCFormDetail,
        isFetchingOCFormDetail,
        applicationOCFormDetail,
        currentOCFormDetail, } = useSelector(PmayNonPmaySelector);
      
      
      
        useEffect(() => {
            dispatch(getSummaryDetails());
            dispatch(clearImageUrl());
        }, [])
    
    
        const Ocvalue = data?.ocDetails ? JSON.parse(data.ocDetails) : { ocDetails: defaultValues.ocDetails };
    const { isFileFetching, isFileSuccess, imageUrl, isFileError, fileErrorMessage } = useSelector(fileUploadSelector);  
//console.log(JSON.parse(data?.ocDetails))
    const formik = useFormik({
        initialValues:Ocvalue,
        validationSchema,
        onSubmit: values => {
            const formData = {
                ocDetails: values.ocDetails.map((ocDetail) => ({
                    date: ocDetail.date,
                    totalBUA: ocDetail.totalBUA,
                    balanceBUA: ocDetail.balanceBUA,
                    particulars: ocDetail.particulars,
                    approvedPlan: ocDetail.approvedPlan,
                })),
            };
            console.log(JSON.stringify({ ocDetails: formData }));
            dispatch(OCFormDetailed(JSON.stringify({ ocDetails: formData })));
        },
    });

    useEffect(() => {
        if (isSuccessOCFormDetail) {
            dispatch(clearImageUrl());
            dispatch(RegistrationStepperSave("5"));
        }
    }, [isSuccessOCFormDetail, dispatch]);

    useEffect(() => {
        if (isFileSuccess && imageUrl !== '') {
            const newOCDetails = [...formik.values.ocDetails];
            if (currentFileIndex !== null) {
                newOCDetails[currentFileIndex].approvedPlan = imageUrl;
                formik.setFieldValue('ocDetails', newOCDetails);
                setCurrentFileIndex(null);
                dispatch(clearImageUrl());
            }
        }
    }, [isFileSuccess, imageUrl, currentFileIndex, formik, dispatch]);

    const handleInputChange = (index, field, value) => {
        if (value.length <= 6) {
            const newOCDetails = [...formik.values.ocDetails];
            newOCDetails[index][field] = value;
            formik.setFieldValue('ocDetails', newOCDetails);
        }
    };
    const handleOtherChange = (index, field, value) => {
        
            const newOCDetails = [...formik.values.ocDetails];
            newOCDetails[index][field] = value;
            formik.setFieldValue('ocDetails', newOCDetails);
        
    };

    const handleFileChange = (index, event) => {
        const file = event.currentTarget.files[0];
        if (file) {
            const newOCDetails = [...formik.values.ocDetails];
            newOCDetails[index].approvedPlanUrl = URL.createObjectURL(file);
            formik.setFieldValue('ocDetails', newOCDetails);
            const sendData = {
                doc: file,
                docName: `Approved Plan ${index + 1}`,
            };
            setCurrentFileIndex(index);
            dispatch(fileDataUpload(sendData));
        }
    };

    const addMoreOC = () => {
        const newOC = { date: '', totalBUA: '', balanceBUA: '', particulars: '', approvedPlan: null, approvedPlanUrl: '' };
        formik.setFieldValue('ocDetails', [...formik.values.ocDetails, newOC]);
    };

    const removeOC = (index) => {
        const newOCDetails = [...formik.values.ocDetails];
        newOCDetails.splice(index, 1);
        formik.setFieldValue('ocDetails', newOCDetails);
    };

    return (
        <>
          {isErrorOCFormDetail && (
                  <AlertBox severity="error">{errorMsgOCFormDetail}</AlertBox>
                )}
                  { isFileError && (
                  <AlertBox severity="error">{fileErrorMessage}</AlertBox>
                )}
        <form onSubmit={formik.handleSubmit}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="oc details table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Date of OC - Part OC</StyledTableCell>
                            <StyledTableCell>Total BUA</StyledTableCell>
                            <StyledTableCell>Balance BUA</StyledTableCell>
                            <StyledTableCell>Particulars of Premises</StyledTableCell>
                            <StyledTableCell>Upload Copy of Approved Plan</StyledTableCell>
                            <StyledTableCell>Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formik.values.ocDetails.map((ocDetail, index) => (
                            <StyledTableRow key={`oc_${index}`}>
                                <StyledTableCell>
                                    <TextField
                                        fullWidth
                                        id={`date_${index}`}
                                        name={`ocDetails[${index}].date`}
                                        value={ocDetail.date}
                                        onChange={(e) => handleOtherChange(index, 'date', e.target.value)}
                                        variant="outlined"
                                        margin="dense"
                                        type='date'
                                        error={formik.touched.ocDetails?.[index]?.date && Boolean(formik.errors.ocDetails?.[index]?.date)}
                                        helperText={formik.touched.ocDetails?.[index]?.date && formik.errors.ocDetails?.[index]?.date}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TextField
                                        fullWidth
                                        id={`totalBUA_${index}`}
                                        name={`ocDetails[${index}].totalBUA`}
                                        value={ocDetail.totalBUA}
                                        onChange={(e) => handleInputChange(index, 'totalBUA', e.target.value)}
                                        variant="outlined"
                                        margin="dense"
                                        type='number'
                                        inputProps={{
                                            maxLength: 6,
                                          }}
                                        error={formik.touched.ocDetails?.[index]?.totalBUA && Boolean(formik.errors.ocDetails?.[index]?.totalBUA)}
                                        helperText={formik.touched.ocDetails?.[index]?.totalBUA && formik.errors.ocDetails?.[index]?.totalBUA}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TextField
                                        fullWidth
                                        id={`balanceBUA_${index}`}
                                        name={`ocDetails[${index}].balanceBUA`}
                                        value={ocDetail.balanceBUA}
                                        onChange={(e) => handleInputChange(index, 'balanceBUA', e.target.value)}
                                        variant="outlined"
                                        margin="dense"
                                        type='number'
                                        inputProps={{
                                            maxLength: 6,
                                          }}
                                        error={formik.touched.ocDetails?.[index]?.balanceBUA && Boolean(formik.errors.ocDetails?.[index]?.balanceBUA)}
                                        helperText={formik.touched.ocDetails?.[index]?.balanceBUA && formik.errors.ocDetails?.[index]?.balanceBUA}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TextField
                                        fullWidth
                                        id={`particulars_${index}`}
                                        name={`ocDetails[${index}].particulars`}
                                        value={ocDetail.particulars}
                                        onChange={(e) => handleOtherChange(index, 'particulars', e.target.value)}
                                        variant="outlined"
                                        margin="dense"
                                        type='text'
                                        inputProps={{
                                            maxLength: 50,
                                          }}
                                        error={formik.touched.ocDetails?.[index]?.particulars && Boolean(formik.errors.ocDetails?.[index]?.particulars)}
                                        helperText={formik.touched.ocDetails?.[index]?.particulars && formik.errors.ocDetails?.[index]?.particulars}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <input
                                        accept="image/*, application/pdf"
                                        id={`approvedPlan_${index}`}
                                        name={`ocDetails[${index}].approvedPlan`}
                                        type="file"
                                        onChange={(e) => handleFileChange(index, e)}
                                        style={{ display: 'none' }}
                                    />
                                    <label htmlFor={`approvedPlan_${index}`}>
                                        <Button
                                            variant="contained"
                                            component="span"
                                            color="primary"
                                        >
                                            Upload
                                        </Button>
                                    </label>
                                    {ocDetail.approvedPlan && (
                                        <div>
                                            {ocDetail.approvedPlan.endsWith('.pdf') ? (
                                                <a href={ocDetail.approvedPlan} target="_blank" rel="noopener noreferrer">View PDF</a>
                                            ) : (
                                                <img
                                                    src={ocDetail.approvedPlan}
                                                    alt={`Approved Plan ${index}`}
                                                    style={{ maxWidth: '100px', marginTop: '10px' }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <IconButton
                                        onClick={() => removeOC(index)}
                                        aria-label="delete"
                                        color="secondary"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={addMoreOC}
                style={{ marginTop: '10px' }}
            >
                <AddCircleIcon style={{ marginRight: '5px' }} />
                Add More
            </Button>

            <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isFetchingOCFormDetail}
        >
          {isFetchingOCFormDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
          {isFetchingOCFormDetail ? 'Saving...' : 'Save & Continue'}
        </Button>
      </Grid>
        </form>
        </>
    );
};

export default OCForm;

