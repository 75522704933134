import React from "react";

import TransactionDetails from "../../organisms/ProfilePageComponents/TransactionDetails/TransactionDetails";
import ProfileLayout from "../Layout/ProfileLayout";

function TransactionDetailsPage() {
    return (
        <ProfileLayout isStepper={false} noScrollIs={true}>
            <TransactionDetails />
        </ProfileLayout>
    );
}

export default TransactionDetailsPage;
