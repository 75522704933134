// import React, { useEffect, useState } from 'react';
// import { withStyles, makeStyles } from '@material-ui/core/styles';
// import { Checkbox, FormControlLabel, TextField, Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
// import { useFormik } from 'formik';
// import { useDispatch, useSelector } from 'react-redux';
// import { BuaFormDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
// import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';

// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }))(TableRow);

// const useStyles = makeStyles({
//   table: {
//     minWidth: 700,
//   },
// });

// const BUAForm = (props) => {
//   const { active,data } = props;
//   const classes = useStyles();
//   const dispatch = useDispatch();
//     const { isFetchingBuaFormDetail,
//       isSuccessBuaFormDetail } = useSelector(PmayNonPmaySelector)
      
      
//       const defaultValues = {
//         // originalBUA: {
//         //   totalBUA: '',
//         //   buaAssigned: '',
//         //   buaPropose: '',
//         //   balBUA: ''
//         // },
//         // additionalBUA: {
//         //   totalBUA: '',
//         //   buaAssigned: '',
//         //   buaPropose: '',
//         //   balBUA: ''
//         // }
//         original_bua -		enum(yes,no)
// original_total_bua - 	varchar 50
// original_bua_already - 	varchar 50
// original_bua_now - 	varchar 50
// original_bua_bal - 	varchar 50
// addl_bua -		enum(yes,no)
// addl_total_bua - 	varchar 50
// addl_bua_already - 	varchar 50
// addl_bua_now - 	varchar 50
// addl_bua_bal - 	varchar 50

//       };
    
    
 
//   const handleSubmit = (values) => {
//     const  formData = {
//       originalBUA: {
//         totalBUA: values.originalBUA.totalBUA,
//         buaAssigned: values.originalBUA.buaAssigned,
//         buaPropose: values.originalBUA.buaPropose,
//         balBUA: values.originalBUA.balBUA,
//       },
//       additionalBUA: {
//         totalBUA: values.additionalBUA.totalBUA,
//         buaAssigned: values.additionalBUA.buaAssigned,
//         buaPropose: values.additionalBUA.buaPropose,
//         balBUA: values.additionalBUA.balBUA,
//       },
//     };

//     console.log(JSON.stringify({buaFormDetails:formData}));
//      dispatch(BuaFormDetailed(JSON.stringify({ buaFormDetails: formData })));
//   };

//   useEffect(() => {
//     if (isSuccessBuaFormDetail) {
        
//         dispatch(RegistrationStepperSave("6"))
//     }
// }, [isSuccessBuaFormDetail]);
// const initialValues = data?.buaFormDetails ? JSON.parse(data.buaFormDetails) : defaultValues;
//   const formik = useFormik({
//     initialValues:initialValues,
//     onSubmit: handleSubmit,
//   });

//   return (
//     <form onSubmit={formik.handleSubmit}>
//       <TableContainer component={Paper}>
//         <Table className={classes.table} aria-label="BUA details table">
//           <TableHead>
//             <TableRow>
//               <StyledTableCell></StyledTableCell>
//               <StyledTableCell>Total BUA</StyledTableCell>
//               <StyledTableCell>BUA already Assigned</StyledTableCell>
//               <StyledTableCell>BUA now Propose to mortgage</StyledTableCell>
//               <StyledTableCell>Bal. BUA</StyledTableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {/* Original BUA */}
//             <StyledTableRow>
//               <StyledTableCell>
//                 <FormControlLabel
//                   control={<Checkbox />}
//                   label="Original BUA"
//                 />
//               </StyledTableCell>
//               <StyledTableCell>
//               <TextField
//                   fullWidth
//                   id="originalBUA_totalBUA"
//                   name="originalBUA.totalBUA"
//                   value={formik.values.originalBUA.totalBUA}
//                   onChange={formik.handleChange}
//                   variant="outlined"
//                   margin="dense"
//                 />
//               </StyledTableCell>
//               <StyledTableCell>
//               <TextField
//                   fullWidth
//                   id="originalBUA_buaAssigned"
//                   name="originalBUA.buaAssigned"
//                   value={formik.values.originalBUA.buaAssigned}
//                   onChange={formik.handleChange}
//                   variant="outlined"
//                   margin="dense"
//                 />
//               </StyledTableCell>
//               <StyledTableCell>
//               <TextField
//                   fullWidth
//                   id="originalBUA_buaPropose"
//                   name="originalBUA.buaPropose"
//                   value={formik.values.originalBUA.buaPropose}
//                   onChange={formik.handleChange}
//                   variant="outlined"
//                   margin="dense"
//                 />
//               </StyledTableCell>
//               <StyledTableCell>
//               <TextField
//                   fullWidth
//                   id="originalBUA_balBUA"
//                   name="originalBUA.balBUA"
//                   value={formik.values.originalBUA.balBUA}
//                   onChange={formik.handleChange}
//                   variant="outlined"
//                   margin="dense"
//                 />
//               </StyledTableCell>
//             </StyledTableRow>

//             {/* Additional BUA */}
//             <StyledTableRow>
//               <StyledTableCell>
//                 <FormControlLabel
//                   control={<Checkbox />}
//                   label="Addl. BUA"
//                 />
//               </StyledTableCell>
//               <StyledTableCell>
//               <TextField
//                   fullWidth
//                   id="additionalBUA_totalBUA"
//                   name="additionalBUA.totalBUA"
//                   value={formik.values.additionalBUA.totalBUA}
//                   onChange={formik.handleChange}
//                   variant="outlined"
//                   margin="dense"
//                 />
//               </StyledTableCell>
//               <StyledTableCell>
//               <TextField
//                   fullWidth
//                   id="additionalBUA_buaAssigned"
//                   name="additionalBUA.buaAssigned"
//                   value={formik.values.additionalBUA.buaAssigned}
//                   onChange={formik.handleChange}
//                   variant="outlined"
//                   margin="dense"
//                 />
//               </StyledTableCell>
//               <StyledTableCell>
//               <TextField
//                   fullWidth
//                   id="additionalBUA_buaPropose"
//                   name="additionalBUA.buaPropose"
//                   value={formik.values.additionalBUA.buaPropose}
//                   onChange={formik.handleChange}
//                   variant="outlined"
//                   margin="dense"
//                 />
//               </StyledTableCell>
//               <StyledTableCell>
//               <TextField
//                   fullWidth
//                   id="additionalBUA_balBUA"
//                   name="additionalBUA.balBUA"
//                   value={formik.values.additionalBUA.balBUA}
//                   onChange={formik.handleChange}
//                   variant="outlined"
//                   margin="dense"
//                 />
//               </StyledTableCell>
//             </StyledTableRow>
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {/* Submit button */}
//       <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px' }}>Submit</Button>
//     </form>
//   );
// };

// export default BUAForm;


import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, TextField, Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { BuaFormDetailed, getSummaryDetails, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
import AlertBox from '../../../atoms/AlertBox/AlertBox';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const BUAForm = (props) => {
  const { active, data } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isFetchingBuaFormDetail, isSuccessBuaFormDetail,isErrorBuaFormDetail,errorMsgBuaFormDetail } = useSelector(PmayNonPmaySelector);
  const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
  
  useEffect(() => {
    
    dispatch(getSummaryDetails());
   
  }, [])
  const getInitialValue = (field) => {
    return summaryData ? summaryData[0]?.[field] : data?.[field] || '';
  };
  const defaultValues = {
    original_bua:getInitialValue('original_bua'),
    original_total_bua:getInitialValue('original_total_bua'),
    original_bua_already:getInitialValue('original_bua_already'),
    original_bua_now: getInitialValue('original_bua_now'),
    original_bua_bal: getInitialValue('original_bua_bal'),
    addl_bua:getInitialValue('addl_bua'),
    addl_total_bua:getInitialValue('addl_total_bua'),
    addl_bua_already:getInitialValue('addl_bua_already'),
    addl_bua_now:getInitialValue('addl_bua_now'),
    addl_bua_bal:getInitialValue('addl_bua_bal'),
  };

  const handleSubmit = (values) => {
    const formData = {
      original_bua: values.original_bua,
      original_total_bua: values.original_total_bua,
      original_bua_already: values.original_bua_already,
      original_bua_now: values.original_bua_now,
      original_bua_bal: values.original_bua_bal,
      addl_bua: values.addl_bua,
      addl_total_bua: values.addl_total_bua,
      addl_bua_already: values.addl_bua_already,
      addl_bua_now: values.addl_bua_now,
      addl_bua_bal: values.addl_bua_bal,
    };

    console.log(JSON.stringify(formData ));
    dispatch(BuaFormDetailed(  formData ));
  };

  useEffect(() => {
    if (isSuccessBuaFormDetail) {
      dispatch(RegistrationStepperSave('6'));
    }
  }, [isSuccessBuaFormDetail]);

  
  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= 6) {
      formik.setFieldValue(name, value);
    }
  };
  const formik = useFormik({
    initialValues: defaultValues,
    onSubmit: handleSubmit,
  });

  return (
    <>
     {isErrorBuaFormDetail && (
                  <AlertBox severity="error">{errorMsgBuaFormDetail}</AlertBox>
                )}
                 
    <form onSubmit={formik.handleSubmit}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="BUA details table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Total BUA</StyledTableCell>
              <StyledTableCell>BUA already Assigned</StyledTableCell>
              <StyledTableCell>BUA now Propose to mortgage</StyledTableCell>
              <StyledTableCell>Bal. BUA</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Original BUA */}
            <StyledTableRow>
              <StyledTableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="original_bua"
                      name="original_bua"
                      checked={formik.values.original_bua === 'yes'}
                      onChange={(e) =>
                        formik.setFieldValue('original_bua', e.target.checked ? 'yes' : 'no')
                      }
                    />
                  }
                  label="Original BUA"
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  id="original_total_bua"
                  name="original_total_bua"
                  value={formik.values.original_total_bua}
                  onChange={handleNumberChange}
                  type='number'
                
                  variant="outlined"
                  margin="dense"
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  id="original_bua_already"
                  name="original_bua_already"
                  value={formik.values.original_bua_already}
                  onChange={handleNumberChange}
                  type='number'
                
                  variant="outlined"
                  margin="dense"
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  id="original_bua_now"
                  name="original_bua_now"
                  value={formik.values.original_bua_now}
                  type='number'
               
                  onChange={handleNumberChange}
                  variant="outlined"
                  margin="dense"
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  id="original_bua_bal"
                  name="original_bua_bal"
                  type='number'
                
                  value={formik.values.original_bua_bal}
                  onChange={handleNumberChange}
                  variant="outlined"
                  margin="dense"
                />
              </StyledTableCell>
            </StyledTableRow>

            {/* Additional BUA */}
            <StyledTableRow>
              <StyledTableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="addl_bua"
                      name="addl_bua"
                      checked={formik.values.addl_bua === 'yes'}
                      onChange={(e) =>
                        formik.setFieldValue('addl_bua', e.target.checked ? 'yes' : 'no')
                      }
                    />
                  }
                  label="Addl. BUA"
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  id="addl_total_bua"
                  name="addl_total_bua"
                  value={formik.values.addl_total_bua}
                  onChange={handleNumberChange}
                  type='number'
                
                  variant="outlined"
                  margin="dense"
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  id="addl_bua_already"
                  name="addl_bua_already"
                  type='number'
               
                  value={formik.values.addl_bua_already}
                  onChange={handleNumberChange}
                  variant="outlined"
                  margin="dense"
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  id="addl_bua_now"
                  name="addl_bua_now"
                 
                  value={formik.values.addl_bua_now}
                  onChange={handleNumberChange}
                  type='number'
                 
                  variant="outlined"
                  margin="dense"
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  id="addl_bua_bal"
                  name="addl_bua_bal"
                
                  value={formik.values.addl_bua_bal}
                  onChange={handleNumberChange}
                  variant="outlined"
                  type='number'
                 
                  margin="dense"
                />
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Submit button */}
      <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isFetchingBuaFormDetail}
        >
          {isFetchingBuaFormDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
          {isFetchingBuaFormDetail ? 'Saving...' : 'Save & Continue'}
        </Button>
        </Grid>
    </form>
    </>
  );
};

export default BUAForm;
