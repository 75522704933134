import React from 'react'
import PaymentSuccessLayout from '../Layout/PaymentSuccessLayout'
import ChangeNamePaymentSuccessful from '../../organisms/ProfilePageComponents/OtherRequestPageComponents/ChangeNamePageComponent/ChangeNamePaymentSuccessfulComponents/ChangeNamePaymentSuccessful'

const ChangeNamePaymentSuccessPage = () => {
  return (
    <PaymentSuccessLayout>
      <ChangeNamePaymentSuccessful />
    </PaymentSuccessLayout>
  )
}

export default ChangeNamePaymentSuccessPage