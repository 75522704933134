// import React, { useEffect, useRef, useState } from 'react';
// import { useFormik } from 'formik';
// import { FormControlLabel, Checkbox, TextField, Grid, CircularProgress, Box, Typography, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
// import { initialPagesStyles } from '../../InitialPagesComponents/InitialPages.styles';
// import { useDispatch, useSelector } from 'react-redux';
// import { clearImageUrl, fileDataUpload, fileUploadSelector } from '../../../../redux/features/file/FileUploadSlice';
// import { SupportedFormats } from '../../../../utils/Common';
// import UploadedKyc from "../../../../assets/UploadedKyc.svg";
// import { FileUploadIcon } from '../../../atoms/SvgIcons/SvgIcons';
// import { getSummaryDetails, LeeseeDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
// import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
// import AlertBox from '../../../atoms/AlertBox/AlertBox';
// import * as Yup from 'yup';


// const SubLicenceDetail = (props) => {
//     const { active,  } = props;
//     const { t } = useTranslation("InitialPageTrans");
//     const classes = initialPagesStyles();
//     const dispatch = useDispatch();

//     const [uploadingField, setUploadingField] = useState('');
//     const [isUploaded, setUploaded] = useState(false);

//     const fileInputRefA = useRef(null);
//     const [isImageA, setIsImageA] = useState(false);
//     const [approvedPlanAUrl, setApprovedPlanAUrl] = useState('');
//     const [approvalPlanAPreview, setApprovalPlanAPreview] = useState(null);
//     const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
//     const [isMortgaged, setIsMortgaged] = useState(false);
//     const [isOnLeaveLicense, setIsOnLeaveLicense] = useState(false);
// const [data,setData]=useState([]);
//     const {
//         isFileFetching,
//         isFileSuccess,
//         imageUrl,
//         isFileError,
//         fileErrorMessage,
//     } = useSelector(fileUploadSelector);
//     const { isFetchingLeeseeDetail,
//         isSuccessLeeseeDetail ,isErrorLeeseeDetail,
//         errorMsgLeeseeDetail,} = useSelector(PmayNonPmaySelector)


//         const getInitialValue = (field) => {
//             return summaryData ? summaryData[0]?.[field] : data?.[field] || '';
//           };
//         const initialValues = {
//         lessee_name: getInitialValue('lessee_name'),
//         sub_lessee_name: getInitialValue('sub_lessee_name'),
//         lessee_society_form: getInitialValue('lessee_society_form'),
//         lessee_certificate_no: getInitialValue('lessee_certificate_no'),
//         lessee_share_certificate_doc: data?.lessee_share_certificate_doc || '',
//         lessee_pan_no:getInitialValue('lessee_pan_no')
//     };

//     const validationSchema = Yup.object({
//         lessee_name: Yup.string().required('Lessee name is required'),

//         lessee_certificate_no: Yup.string().required('Certificate No. is required'),
//         lessee_pan_no: Yup.string().required('PAN Card No. is required'),
//         lessee_share_certificate_doc: Yup.string().required('Share certificate is required'),
//         lessee_society_form: Yup.boolean(),
//     });
//     const formik = useFormik({
//         initialValues,
//         validationSchema,
//         enableReinitialize:true,
//         onSubmit: values => {
//             // Handle form submission logic here
//             const data = {
//                 ...values,
//                 lessee_share_certificate_doc: approvedPlanAUrl,
//                 lessee_society_form: formik.values.lessee_society_form ? 'yes' : 'no',
//             }
//             console.log(data,"data");
//          dispatch(LeeseeDetailed(data));
//         },
//     });
//     useEffect(() => {
//        // dispatch(getSummaryDetails());
//         dispatch(clearImageUrl());
//     }, [])

//     function hasImageExtension(url) {
//         if (!url) {
//             return false;
//           }
//         const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.tiff'];
//         return imageExtensions.some(extension => url.toLowerCase().endsWith(extension));
//       }

//       // Usage


//     // useEffect(()=>{
//     //   if(isSuccessResSummary && summaryData)
//     //   {
//     //     const proApprovedPlanDocA = summaryData?.DocumentDetails?.lessee_share_certificate_doc;

//     //     formik.setFieldValue( "lessee_share_certificate_doc",proApprovedPlanDocA)

//     //     if (hasImageExtension(proApprovedPlanDocA)) {
//     //      setIsImageA(true)
//     //      setApprovalPlanAPreview(proApprovedPlanDocA);
//     //     } else {
//     //      setIsImageA(false)
//     //     }




//     //   }

//     // },[isSuccessResSummary,summaryData])

//     useEffect(() => {
//         if (isSuccessLeeseeDetail) {

//             dispatch(RegistrationStepperSave("3"))
//         }
//     }, [isSuccessLeeseeDetail]);

//     const onReUpload = (fieldName) => {
//         if (fieldName === 'lessee_share_certificate_doc' && fileInputRefA.current) {
//             fileInputRefA.current.click();
//         }
//     };

//     useEffect(() => {
//         if (isFileSuccess && imageUrl) {
//             if (uploadingField === 'lessee_share_certificate_doc') {
//                 setApprovedPlanAUrl(imageUrl);
//                 dispatch(clearImageUrl());
//             }
//             setUploadingField('');
//         }
//     }, [isFileSuccess, imageUrl, uploadingField, dispatch]);

//     const handleFileChange = (event, fieldName) => {
//         const file = event.currentTarget.files[0];
//         if (file) {
//             setUploadingField(fieldName);
//             console.log(file, "filedasdffsf")
//             formik.setFieldValue("lessee_share_certificate_doc", file.name);
//             const data = { doc: file, docName: fieldName };
//             dispatch(fileDataUpload(data));
//             if (fieldName === 'lessee_share_certificate_doc') {
//                 if (SupportedFormats.DocsFormats.includes(file.type)) {
//                     switch (file.type) {
//                         case "image/jpeg":
//                         case "image/png":
//                             setIsImageA(true);
//                             break;
//                         case "application/pdf":
//                         default:
//                             setIsImageA(false);
//                     }
//                 }
//                 setApprovalPlanAPreview(URL.createObjectURL(file));

//             }
//         }
//     };

//     useEffect(() => {
//         if (data?.lessee_share_certificate_doc) {
//             setUploaded(true);
//         }
//     }, [data]);

//     const handleCheckboxChange = (event) => {
//         const { checked, name } = event.target;
//         formik.setFieldValue(name, checked);  // Directly set boolean value
//     };

//     useEffect(() => {
//         if (isFileSuccess) {
//             setUploaded(true);
//         }
//     }, [isFileSuccess]);

//     return (
//         <>
//          {isErrorLeeseeDetail && (
//                   <AlertBox severity="error">{errorMsgLeeseeDetail}</AlertBox>
//                 )}
//                   { isFileError && (
//                   <AlertBox severity="error">{fileErrorMessage}</AlertBox>
//                 )}

//         <form onSubmit={formik.handleSubmit}>
//             <Grid container spacing={1}>
//                 <Grid item xs={6}>
//                     <TextField
//                         fullWidth
//                         id="lessee_name"
//                         name="lessee_name"
//                         label="Sub Lessee Name"
//                         value={formik.values.lessee_name}
//                         onChange={formik.handleChange}
//                         error={formik.touched.lessee_name && Boolean(formik.errors.lessee_name)}
//                         helperText={formik.touched.lessee_name && formik.errors.lessee_name}
//                         required
//                         variant="outlined"
//                         margin="dense"
//                     />
//                 </Grid>
//                 {/* <Grid item xs={6}>
//                     <TextField
//                         fullWidth
//                         id="sub_lessee_name"
//                         name="sub_lessee_name"
//                         label="Sub-Lessee Mortgagor (If Applicable)"
//                         value={formik.values.sub_lessee_name}
//                         onChange={formik.handleChange}
//                         variant="outlined"
//                         margin="dense"
//                     />
//                 </Grid> */}
//                 <Grid item xs={6}>
//                             <TextField
//                                 fullWidth
//                                 id="auth_person_aadhar_no"
//                                 name="auth_person_aadhar_no"
//                                 label="Sub Lessee Aadhar No"
//                                 value={formik.values.auth_person_aadhar_no}

//                                 onInput={(e) => {
//                                   e.target.value = e.target.value.replace(/\D/g, "").split(/(?:([\d]{4}))/g).filter(s => s.length > 0).join("-");
//                                 }}
//                                 inputProps={{
//                                   maxLength: 14,
//                                 }}

//                                 onChange={formik.handleChange}
//                                 variant="outlined"
//                                 margin="dense"
//                                 // error={formik.errors.auth_person_aadhar_no}
//                                 // helperText={formik.touched.auth_person_aadhar_no && formik.errors.auth_person_aadhar_no}
//                             />
//                         </Grid>
//                 <Grid item xs={6}>
//                     <TextField
//                         fullWidth
//                         id="lessee_pan_no"
//                         name="lessee_pan_no"
//                         label="Sub Leese PAN Card No."
//                         value={formik.values.lessee_pan_no}
//                         onChange={formik.handleChange}
//                         variant="outlined"
//                         margin="dense"
//                         required
//                         inputProps={{
//                           maxLength: 10,
//                         }}
//                         autoFocus={true}
//                         onInput={(e) =>
//                           (e.target.value = ("" + e.target.value).toUpperCase())
//                         }
//                         error={formik.touched.lessee_pan_no && Boolean(formik.errors.lessee_pan_no)}
//                             helperText={formik.touched.lessee_pan_no && formik.errors.lessee_pan_no}
//                     />
//                 </Grid>
//                 {/* <Grid item xs={6}>
//                     <input
//                         ref={fileInputRefA}
//                         style={{ display: 'none' }}
//                         type="file"
//                         accept="image/*, application/pdf"
//                         id="lessee_share_certificate_doc"
//                         name="lessee_share_certificate_doc"
//                         onChange={(event) => handleFileChange(event, 'lessee_share_certificate_doc')}
//                     />
//                     <TextField
//                         fullWidth
//                         label='Upload Sub Lessee Share Certificate'
//                         variant="outlined"
//                         margin="dense"
//                         disabled
//                         value={formik.values.lessee_share_certificate_doc}

//                         InputProps={{
//                             endAdornment: (
//                                 <Button
//                                     variant="contained"
//                                     size="small"
//                                     color="primary"
//                                     onClick={() => fileInputRefA.current.click()}
//                                 >
//                                     Browse
//                                 </Button>
//                             ),
//                         }}
//                     />
//                     {approvalPlanAPreview && (
//                         <Box>
//                             <Typography className={classes.kycCaption} variant="subtitle1">
//                                 Preview Lessee Share Certificate
//                             </Typography>
//                             {isImageA ? (
//                                 <Box className={classes.panCardPreviewCard}>
//                                     <img
//                                         className={classes.panPreviewImg}
//                                         src={approvalPlanAPreview}
//                                         alt="uploaded successfully"
//                                     />
//                                 </Box>
//                             ) : (
//                                 <Box textAlign="center" className={classes.panCardPreviewCard}>
//                                     <img className={classes.panPreviewImg} src={UploadedKyc} alt="uploaded successfully" />
//                                     <Typography className={classes.kycCaption}>
//                                         {t("kycBank.formControl.upload.success")}
//                                     </Typography>
//                                 </Box>
//                             )}
//                             <Box textAlign="left" marginTop={2}>
//                                 <Typography className={classes.kycCaption} variant="subtitle1">
//                                     Uploaded Lessee Share Certificate
//                                 </Typography>
//                             </Box>
//                             <Box className={classes.fileViewArea}>
//                                 <Grid container spacing={1} alignItems="center">
//                                     <Grid item xs="auto">
//                                         <FileUploadIcon color="primary" />
//                                     </Grid>
//                                     <Grid item xs zeroMinWidth>
//                                         <Typography variant="body2" className={classes.fileUrlPreview}>
//                                             {approvedPlanAUrl || "--"}
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item xs="auto">
//                                         <Button
//                                             variant="text"
//                                             size="small"
//                                             color="primary"
//                                             onClick={() => onReUpload('lessee_share_certificate_doc')}
//                                         >
//                                             {t("kycBank.formControl.upload.reupload")}
//                                         </Button>
//                                     </Grid>
//                                 </Grid>
//                             </Box>
//                         </Box>
//                     )}
//                 </Grid> */}
//                 <Grid item xs={6}>
//                         <FormControl fullWidth variant="outlined" margin="dense">
//                             <InputLabel id="companyType-label">Company Type</InputLabel>
//                             <Select
//                                 labelId="companyType-label"
//                                 id="companyType"
//                                 name="companyType"
//                                 value={formik.values.companyType}
//                                 onChange={formik.handleChange}
//                                 label="Company Type"
//                                 required
//                             >
//                                 <MenuItem value="">
//                                     <em>None</em>
//                                 </MenuItem>
//                                 <MenuItem value="financial_institute">Financial Institute</MenuItem>
//                                 <MenuItem value="front_ranking_company">Front Ranking Company</MenuItem>
//                                 <MenuItem value="it_data_processing_company">IT Data Processing Company</MenuItem>
//                                 <MenuItem value="regulatory_authorities">Regulatory Authorities</MenuItem>
//                                 <MenuItem value="business_support_services">Business Support Services</MenuItem>
//                             </Select>
//                         </FormControl>
//                     </Grid>
//                     {formik.values.companyType === 'financial_institute' && (
//                         <Grid item xs={6}>
//                             <TextField
//                                 fullWidth
//                                 id="financialInstituteDocs"
//                                 name="financialInstituteDocs"
//                                 label="Upload ROC & Certificate of RBI"
//                                 type="file"
//                                 InputLabelProps={{ shrink: true }}
//                                 inputProps={{
//                                     accept: SupportedFormats.DocsFormats.join(", ")
//                                 }}
//                                 onChange={(event) => handleFileChange(event, 'financialInstituteDocs')}
//                                 variant="outlined"
//                                 margin="dense"
//                                 required
//                             />
//                         </Grid>
//                     )}
//                     {formik.values.companyType === 'front_ranking_company' && (
//                         <Grid item xs={6}>
//                             <TextField
//                                 fullWidth
//                                 id="frontRankingCompanyDocs"
//                                 name="frontRankingCompanyDocs"
//                                 label="Upload ROC & Turnover Certificate from CA"
//                                 type="file"
//                                 InputLabelProps={{ shrink: true }}
//                                 inputProps={{
//                                     accept: SupportedFormats.DocsFormats.join(", ")
//                                 }}
//                                 onChange={(event) => handleFileChange(event, 'frontRankingCompanyDocs')}
//                                 variant="outlined"
//                                 margin="dense"
//                                 required
//                             />
//                         </Grid>
//                     )}
//                     {formik.values.companyType === 'it_data_processing_company' && (
//                         <Grid item xs={6}>
//                             <TextField
//                                 fullWidth
//                                 id="itDataProcessingDocs"
//                                 name="itDataProcessingDocs"
//                                 label="Upload Registration Certificate & Document of Nature of Business"
//                                 type="file"
//                                 InputLabelProps={{ shrink: true }}
//                                 inputProps={{
//                                     accept: SupportedFormats.DocsFormats.join(", ")
//                                 }}
//                                 onChange={(event) => handleFileChange(event, 'itDataProcessingDocs')}
//                                 variant="outlined"
//                                 margin="dense"
//                                 required
//                             />
//                         </Grid>
//                     )}
//                     {formik.values.companyType === 'regulatory_authorities' && (
//                         <Grid item xs={6}>
//                             <TextField
//                                 fullWidth
//                                 id="regulatoryAuthorityDocs"
//                                 name="regulatoryAuthorityDocs"
//                                 label="Upload Registration Certificate & Enactment or Government Order"
//                                 type="file"
//                                 InputLabelProps={{ shrink: true }}
//                                 inputProps={{
//                                     accept: SupportedFormats.DocsFormats.join(", ")
//                                 }}
//                                 onChange={(event) => handleFileChange(event, 'regulatoryAuthorityDocs')}
//                                 variant="outlined"
//                                 margin="dense"
//                                 required
//                             />
//                         </Grid>
//                     )}
//                     {formik.values.companyType === 'business_support_services' && (
//                         <Grid item xs={6}>
//                             <TextField
//                                 fullWidth
//                                 id="businessSupportDocs"
//                                 name="businessSupportDocs"
//                                 label="Upload Registration Certificate &/or Authenticate Document Indicating Nature of Business"
//                                 type="file"
//                                 InputLabelProps={{ shrink: true }}
//                                 inputProps={{
//                                     accept: SupportedFormats.DocsFormats.join(", ")
//                                 }}
//                                 onChange={(event) => handleFileChange(event, 'businessSupportDocs')}
//                                 variant="outlined"
//                                 margin="dense"
//                                 required
//                             />
//                         </Grid>
//                     )}

//             </Grid>
//             <Grid item xs={6}>
//           <FormControl fullWidth variant="outlined" margin="dense">
//             <InputLabel id="isMortgaged-label">
//               Is Premises Mortgaged?
//             </InputLabel>
//             <Select
//               labelId="isMortgaged-label"
//               id="isMortgaged"
//               name="isMortgaged"
//               value={formik.values.isMortgaged}
//               onChange={(e) => {
//                 formik.handleChange(e);
//                 setIsMortgaged(e.target.value === "yes");
//               }}
//               label="Is Premises Mortgaged?"
//               required
//             >
//               <MenuItem value="">
//                 <em>None</em>
//               </MenuItem>
//               <MenuItem value="yes">Yes</MenuItem>
//               <MenuItem value="no">No</MenuItem>
//             </Select>
//           </FormControl>
//         </Grid>

//         {isMortgaged && (
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               id="releaseOrNocBankDoc"
//               name="releaseOrNocBankDoc"
//               label="Upload Release or NOC from Bank"
//               type="file"
//               InputLabelProps={{ shrink: true }}
//               inputProps={{
//                 accept: ".pdf,.doc,.docx,.jpg,.jpeg,.png", // Example file formats
//               }}
//               onChange={(event) => {
//                 formik.setFieldValue(
//                   "releaseOrNocBankDoc",
//                   event.currentTarget.files[0]
//                 );
//               }}
//               variant="outlined"
//               margin="dense"
//               required
//             />
//           </Grid>
//         )}

//         {!isMortgaged && (
//           <Grid item xs={6}>
//             <FormControl fullWidth variant="outlined" margin="dense">
//               <InputLabel id="isOnLeaveLicense-label">
//                 Is Premises on Leave & License?
//               </InputLabel>
//               <Select
//                 labelId="isOnLeaveLicense-label"
//                 id="isOnLeaveLicense"
//                 name="isOnLeaveLicense"
//                 value={formik.values.isOnLeaveLicense}
//                 onChange={(e) => {
//                   formik.handleChange(e);
//                   setIsOnLeaveLicense(e.target.value === "yes");
//                 }}
//                 label="Is Premises on Leave & License?"
//                 required
//               >
//                 <MenuItem value="">
//                   <em>None</em>
//                 </MenuItem>
//                 <MenuItem value="yes">Yes</MenuItem>
//                 <MenuItem value="no">No</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//         )}

//         {isOnLeaveLicense && (
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               id="nocLicenseeDoc"
//               name="nocLicenseeDoc"
//               label="Upload NOC of Licensee"
//               type="file"
//               InputLabelProps={{ shrink: true }}
//               inputProps={{
//                 accept: ".pdf,.doc,.docx,.jpg,.jpeg,.png", // Example file formats
//               }}
//               onChange={(event) => {
//                 formik.setFieldValue(
//                   "nocLicenseeDoc",
//                   event.currentTarget.files[0]
//                 );
//               }}
//               variant="outlined"
//               margin="dense"
//               required
//             />
//           </Grid>
//         )}

//             <Grid container alignItems="flex-end" style={{ marginTop: 5 }} justifyContent="flex-end">
//                 <Button
//                     type="submit"
//                     variant="contained"
//                     color="primary"
//                     size="large"
//                     disabled={isFetchingLeeseeDetail}
//                 >
//                     {isFetchingLeeseeDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
//                     {isFetchingLeeseeDetail && 'Saving...'}
//                     {!isFetchingLeeseeDetail && 'Save & Continue'}
//                 </Button>
//             </Grid>
//         </form>
//         </>
//     );
// };

// export default SubLicenceDetail;

import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { FormControlLabel, Checkbox, TextField, Grid, CircularProgress, Box, Typography, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { initialPagesStyles } from '../../InitialPagesComponents/InitialPages.styles';
import { useDispatch, useSelector } from 'react-redux';
import { clearImageUrl, fileDataUpload, fileUploadSelector } from '../../../../redux/features/file/FileUploadSlice';
import { SupportedFormats } from '../../../../utils/Common';
import UploadedKyc from "../../../../assets/UploadedKyc.svg";
import { FileUploadIcon } from '../../../atoms/SvgIcons/SvgIcons';
import { getCompanyTypeDetails, getSummaryDetails, LeeseeDetailed, LeeseeSubLicenceDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
import AlertBox from '../../../atoms/AlertBox/AlertBox';
import * as Yup from 'yup';

const SubLicenceDetail = (props) => {
    const { active, data } = props;
    const { t } = useTranslation("InitialPageTrans");
    const classes = initialPagesStyles();
    const dispatch = useDispatch();

    
    const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);

    const { isFetchingCompanyType,

        isSuccessCompanyType,
        isErrorCompanyType,
        errorMessageCompanyType,
        companyTypeData } = useSelector(PmayNonPmaySelector);

    const {
        isFileFetching,
        isFileSuccess,
        imageUrl,
        isFileError,
        fileErrorMessage,
    } = useSelector(fileUploadSelector);
    const {
        isSuccessLeaseSubLicencesDetail,
        isErrorLeaseSubLicencesDetail,
        errorMsgLeaseSubLicencesDetail,
        isFetchingLeaseSubLicencesDetail,
        applicationLeaseSubLicencesDetail } = useSelector(PmayNonPmaySelector)
    const [companyType, setCompanyType] = useState([]);

    const getInitialValue = (field) => {
        return summaryData ? summaryData[0]?.[field] : data?.[field] || '';
    };
    const initialValues = {

        sub_licensee_name: getInitialValue('sub_licensee_name'),
        sub_licensee_aadhaar_no: getInitialValue('sub_licensee_aadhaar_no'),
        sub_licensee_pan_no: getInitialValue('sub_licensee_pan_no'),
        sub_licensee_company_details: getInitialValue(' sub_licensee_company_details'),
        premises_already_given_on_lease: getInitialValue('premises_already_given_on_lease'),
        premises_part_of_mortgaged: getInitialValue('  premises_part_of_mortgaged'),
    };

    const validationSchema = Yup.object({
        lessee_name: Yup.string().required('Lessee name is required'),
        lessee_certificate_no: Yup.string().required('Certificate No. is required'),
        lessee_pan_no: Yup.string().required('PAN Card No. is required'),
        lessee_share_certificate_doc: Yup.string().required('Share certificate is required'),
        lessee_society_form: Yup.boolean(),
    });

    const formik = useFormik({
        initialValues,
        //  validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            const data = {
                ...values,

                premises_part_of_mortgaged: formik.values.premises_part_of_mortgaged === true ? '1' : "0",
                premises_already_given_on_lease: formik.values.premises_already_given_on_lease === true ? "1" : '0',
            }
            // console.log(data, "data");
            dispatch(LeeseeSubLicenceDetailed(data));
        },
    });

    useEffect(() => {
        dispatch(getCompanyTypeDetails());
        dispatch(getSummaryDetails());
        dispatch(clearImageUrl());
    }, [])


    useEffect(() => {
        if (isSuccessCompanyType) {
            setCompanyType(companyTypeData); // Set the properties data fetched from API
        }
    }, [isSuccessCompanyType, companyTypeData]);



    useEffect(() => {
        if (isSuccessLeaseSubLicencesDetail) {
            dispatch(RegistrationStepperSave("7"));
        }
    }, [isSuccessLeaseSubLicencesDetail]);



    const handleCheckboxChange = (event) => {
        const { checked, name } = event.target;
        formik.setFieldValue(name, checked);
    };

 
    return (
        <>
            {isErrorLeaseSubLicencesDetail && (
                <AlertBox severity="error">{errorMsgLeaseSubLicencesDetail}</AlertBox>
            )}
            {isFileError && (
                <AlertBox severity="error">{fileErrorMessage}</AlertBox>
            )}

            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="sub_licensee_name"
                            name="sub_licensee_name"
                            label="Sub Licensee Name"
                            value={formik.values.sub_licensee_name}
                            onChange={formik.handleChange}
                            error={formik.touched.sub_licensee_name && Boolean(formik.errors.sub_licensee_name)}
                            helperText={formik.touched.sub_licensee_name && formik.errors.sub_licensee_name}
                            required
                            variant="outlined"
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="sub_licensee_aadhaar_no"
                            name="sub_licensee_aadhaar_no"
                            label="Sub Lessee Aadhaar No"
                            value={formik.values.sub_licensee_aadhaar_no}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/\D/g, "").split(/(?:([\d]{4}))/g).filter(s => s.length > 0).join("-");
                            }}
                            inputProps={{
                                maxLength: 14,
                            }}
                            onChange={formik.handleChange}
                            variant="outlined"
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="sub_licensee_pan_no"
                            name="sub_licensee_pan_no"
                            label="Sub Lessee PAN Card No."
                            value={formik.values.sub_licensee_pan_no}
                            onChange={formik.handleChange}
                            variant="outlined"
                            margin="dense"
                            required
                            inputProps={{
                                maxLength: 10,
                            }}
                            autoFocus={true}
                            onInput={(e) =>
                                (e.target.value = ("" + e.target.value).toUpperCase())
                            }
                            error={formik.touched.sub_licensee_pan_no && Boolean(formik.errors.sub_licensee_pan_no)}
                            helperText={formik.touched.sub_licensee_pan_no && formik.errors.sub_licensee_pan_no}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined" margin="dense">
                            <InputLabel id="sub_licensee_company_details">Company Type</InputLabel>
                            <Select
                                labelId="sub_licensee_company_details"
                                id="sub_licensee_company_details"
                                name="sub_licensee_company_details"
                                value={formik.values.sub_licensee_company_details}
                                onChange={formik.handleChange}
                                label="Company Type"
                                required
                            >

                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {Object.entries(companyType).map(([key, label]) => (
                                    <MenuItem key={key} value={key}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>



                    {/* Conditional fields based on Premises conditions */}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.premises_part_of_mortgaged}
                                    onChange={handleCheckboxChange}
                                    name="premises_part_of_mortgaged"
                                    color="primary"
                                />
                            }
                            label="Premises is part of mortgaged premises"
                        />
                    </Grid>



                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.premises_already_given_on_lease}
                                    onChange={handleCheckboxChange}
                                    name="premises_already_given_on_lease"
                                    color="primary"
                                />
                            }
                            label="Premises is already given on Lease & License basis"
                        />
                    </Grid>



                    <Grid container alignItems="flex-end" style={{ marginTop: 5 }} justifyContent="flex-end">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={isFetchingLeaseSubLicencesDetail}
                        >
                            {isFetchingLeaseSubLicencesDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
                            {isFetchingLeaseSubLicencesDetail && 'Saving...'}
                            {!isFetchingLeaseSubLicencesDetail && 'Save & Continue'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default SubLicenceDetail;
