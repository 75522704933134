import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndPoint } from "../../../utils/Common";
import moment from "moment";



// export const updateCountDownTime = createAsyncThunk(
//     "countTimer/updateCountDown",
//     async (data, thunkAPI) => {
//       try {
       

export const updateCountDownTime = createAsyncThunk(
         "countTimer/updateCountDown",
        async (data, thunkAPI) => {
      try {
        
        const response = await axios.post(
                      ApiEndPoint + "/Auction/update_eauctionEndDate",
                    data);
        let responseData = await response.data;
        if (response.status === 200) {
          return responseData;
        } else {
          return thunkAPI.rejectWithValue(responseData);
        }
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
export const countTimerSlice = createSlice({
    name: "countTimer",
    initialState: {
      isFetchingCountTimer: false,
      isSuccessCountTimer: false,
      isErrorCountTimer: false,
      errorMsgCountTimer: "",
      countTimerData: {},
      
    },
    reducers: {
      clearTimerState: (state) => {
        state.isFetchingCountTimer = false;
        state.isSuccessCountTimer = false;
        state.isErrorCountTimer = false;
        state.errorMsgCountTimer = "";
     
      },
      clearTimerData: (state) => {
      state.countTimerData = {};
      },
    },


  
extraReducers: {
     [updateCountDownTime.fulfilled]: (state, { payload }) => {
        state.isFetchingCountTimer = false;
        state.isErrorCountTimer = payload.error;
        state.errorMessageCountTimer = payload.message;
        state.isSuccessCountTimer = payload.success;
        state.countTimerData = payload.data;
        console.log(state.countTimerData,"sdsdsds")
      
      },
      [updateCountDownTime.pending]: (state) => {
        state.isFetchingCountTimer = true;
      },
      [updateCountDownTime.rejected]: (state, { payload }) => {
        state.isFetchingCountTimer = false;
        state.errorMessageCountTimer = payload.message;
        state.isErrorCountTimer = payload.error;
      },
  },

  

});

export const { clearTimerState, clearTimerData } =
  countTimerSlice.actions;

export const countTimerSelector = (state) => state.countTimer;
