import React from "react";

import ProfileSummary from "../../organisms/ProfilePageComponents/ProfileSummary/ProfileSummary";
import ProfileLayout from "../Layout/ProfileLayout";

function MyProfilePage() {
    return (
        <ProfileLayout isStepper={false} noScrollIs={true}>
            <ProfileSummary />
        </ProfileLayout>
    );
}

export default MyProfilePage;
