// import { Box, Button, Paper, Step, StepContent, StepIcon, StepLabel, Stepper, Typography, withStyles } from '@material-ui/core';
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { RegistrationStepperSave, RegistrationStepperSelector, getRegistrationProgress } from '../../../../redux/features/registration/registrationStepperSlice';
// import { ColorlibConnector } from '../../ProfilePageComponents/MakeHousePayment/MakeHousePayment.style';
// import { makeStyles } from "@material-ui/core/styles";
// import moment from 'moment';

// const useStyle = makeStyles((theme) => ({
//   stepContent: {
//     marginTop: 0,
//     paddingRight: 0,
//     borderLeft: "2px dashed #E7E7E7",
//     "&.done": {
//       borderLeft: "2px solid rgba(33, 150, 83, 1)",
//     },
//     "&.active": {
//       borderLeft: "2px solid rgba(1, 81, 202, 1)",
//     },
//   },
//   stepper: {
//     '& .MuiStepConnector-vertical': {
//       marginLeft: 18,
//       padding: 0
//     }
//   }
// }))

// const CustomStepIcon = ({ active, completed, icon }) => {
//   return (
//     <div
//       style={{
//         width: 28,
//         height: 28,
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         borderColor: "#C3C7CF",
//         border: 1,
//         borderStyle: "solid",
//         backgroundColor: completed ? '#00437E' : '#FFFFFF',
//         borderRadius: 6,
//         color: completed ? '#FFFFFF' : '#000000', // Change color based on completion
//       }}
//     >
//       {icon}


//     </div>
//   );
// };

// const StyledStepIcon = withStyles((theme) => ({
//   root: {
//     width: 40,
//     height: 40,
//     color: '#bdbdbd',// Default color
//     '&$active': {
//       color: '#F27807', // Active color
//       '&.Mui-error': {
//         color: '#f44336'
//       },
//     },
//     '&$completed': {
//       color: '#4caf50', // Completed color
//       '&.Mui-error': {
//         color: '#f44336'
//       },
//     },
//   },
//   active: {},
//   completed: {},
// }))(StepIcon);

// export default function VerticalLinearStepper() {
//   const { isFetchRegStepper,
//     isSuccessgetRegStepper,
//     getRegStepper,
//     getRegActiveStep,
//     isErrorgetRegStepper,
//     getRegStepperData,
//     errorMessagegetRegStepper,
//     isSuccessRegStepper
//   } = useSelector(RegistrationStepperSelector);
//   const dispatch = useDispatch();
//   const [activeStep, setActiveStep] = useState(0);
//   const classes = useStyle();


//   useEffect(() => {
//     if (isSuccessgetRegStepper) {
//       setActiveStep(getRegActiveStep);
//     }
//   }, [isSuccessgetRegStepper, getRegActiveStep])

  
//   const steps = [
//     { label: "Details of the premises proposed to be mortgage" },
//     { label: "Lessee Details" },
//     { label: "Person authorized for Liaison" },
//     { label: "OC/Part OC of the premises to be assign" },
//     { label: "Whether Premises is part of original BUA/ Addl. BUA (Sq. Mtrs.)" },
//     { label: 'Bank Details' },
//     { label: 'Mode of Assignment' },
//     {label: "Details of the previous NOC granted (if Applicable)"},
//     {label: "Upload Documents"}
//   ];


//   // const handleNext = () => {
//   //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   // };

//   // const handleBack = () => {
//   //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   // };

//   // const handleReset = () => {
//   //   setActiveStep(0);
//   // };

//   return (
    
//     <Box sx={{ maxWidth: 400 }}>
//       {/* <Stepper activeStep={activeStep} orientation="vertical">
//         {steps.map((step, index) => (
//           <Step key={step.label}>
//             <StepLabel StepIconComponent={CustomStepIcon}>
//               {step.label}
//             </StepLabel>
//             <StepContent>
//               <Typography>{step.description}</Typography>
//             </StepContent>
//           </Step>
//         ))}
//       </Stepper> */}
//       <Stepper activeStep={activeStep - 1} className={classes.stepper} connector={<ColorlibConnector />} orientation="vertical">
//         {steps.map((step, index) => (
//           <Step key={step.label}>
//             <StepLabel
//               StepIconComponent={StyledStepIcon}
//             >
//               {step.label}
//               <Typography style={{ color: "#65707D", fontSize: "14px" }}>{step.description}</Typography>
//             </StepLabel>
//             <StepContent className={`${classes.stepContent} ${(activeStep == index + 1) ? "active" : ""} ${activeStep > index + 1 ? "done" : ""}`} >
//               {/* <Typography>{step.description}</Typography> */}
//             </StepContent>
//           </Step>
//         ))}
//       </Stepper>
//       {/* {activeStep === steps.length && (
//         <Paper square elevation={0} sx={{ p: 3 }}>
//           <Typography>All steps completed - you&apos;re finished</Typography>
//           <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//             Reset
//           </Button>
//         </Paper>
//       )} */}
//     </Box>
//   );
// }

import { Box, Button, Paper, Step, StepContent, StepIcon, StepLabel, Stepper, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RegistrationStepperSave, RegistrationStepperSelector, getRegistrationProgress } from '../../../../redux/features/registration/registrationStepperSlice';
import { ColorlibConnector } from '../../ProfilePageComponents/MakeHousePayment/MakeHousePayment.style';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import MortgagePremisesDetails from '../MortgageNocComponents/MortagePremisesDetails';
import LesseeDetails from '../MortgageNocComponents/LesseeDetails';
import MortgageForm from '../MortgageNocComponents/MortgageForm';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    
  },
  stepContent: {
    backgroundColor: 'transparent',
    marginTop: 0,
    paddingRight: 0,
    borderLeft: "2px dashed #E7E7E7",
    "&.done": {
      borderLeft: "2px solid rgba(33, 150, 83, 1)",
    },
    "&.active": {
      borderLeft: "2px solid rgba(1, 81, 202, 1)",
    },
  },
  stepper: {
    backgroundColor: 'transparent', // Remove background color
    
    '& .MuiStepConnector-vertical': {
      marginLeft: 18,
      padding: 0
    }
  },
  stepperContainer: {
    flex: '40%',
    backgroundColor: 'transparent',
    //background:'linear-gradient( 296deg , #00a74a, #15489f)',
    
  },
  contentContainer: {
    flex: '60%',
    paddingLeft: theme.spacing(2),
  }
}))

const CustomStepIcon = ({ active, completed, icon }) => {
  return (
    <div
      style={{
        width: 28,
        height: 28,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: "#C3C7CF",
        border: 1,
        borderStyle: "solid",
        backgroundColor: completed ? '#00437E' : '#FFFFFF',
        borderRadius: 6,
        color: completed ? '#FFFFFF' : '#000000', 
      }}
    >
      {icon}


    </div>
  );
};

const StyledStepIcon = withStyles((theme) => ({
  root: {
    width: 40,
    height: 40,
    color: '#bdbdbd',// Default color
    '&$active': {
      color: '#F27807', // Active color
      '&.Mui-error': {
        color: '#f44336'
      },
    },
    '&$completed': {
      color: '#4caf50', // Completed color
      '&.Mui-error': {
        color: '#f44336'
      },
    },
  },
  active: {},
  completed: {},
}))(StepIcon);



// export default function VerticalLinearStepper() {
//   const {
//     isFetchRegStepper,
//     isSuccessgetRegStepper,
//     getRegStepper,
//     getRegActiveStep,
//     isErrorgetRegStepper,
//     getRegStepperData,
//     errorMessagegetRegStepper,
//     isSuccessRegStepper
//   } = useSelector(RegistrationStepperSelector);
//   const dispatch = useDispatch();
//   const [activeStep, setActiveStep] = useState(0);
//   const classes = useStyle();

//   useEffect(() => {
//     if (isSuccessgetRegStepper) {
//       setActiveStep(getRegActiveStep);
//     }
//   }, [isSuccessgetRegStepper, getRegActiveStep]);

//     const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//   };
//   const steps = [
//     { label: "Details of the premises proposed to be mortgage",  },
//     { label: "Lessee Details", component: <CustomComponent2 /> },
//     { label: "Person authorized for Liaison", component: <CustomComponent3 /> },
//     { label: "OC/Part OC of the premises to be assign", component: <CustomComponent1 /> },
//     { label: "Whether Premises is part of original BUA/ Addl. BUA (Sq. Mtrs.)", component: <CustomComponent2 /> },
//     { label: 'Bank Details', component: <CustomComponent3 /> },
//     { label: 'Mode of Assignment', component: <CustomComponent1 /> },
//     { label: "Details of the previous NOC granted (if Applicable)", component: <CustomComponent2 /> },
//     { label: "Upload Documents", component: <CustomComponent3 /> }
//   ];

//   return (
//     <>
//       <Stepper activeStep={activeStep - 1} className={classes.stepper} connector={<ColorlibConnector />} orientation="vertical">
//         {steps.map((step, index) => (
//           <Step key={step.label}>
//             <StepLabel StepIconComponent={StyledStepIcon}>
//               {step.label}
//             </StepLabel>
//             <StepContent className={`${classes.stepContent} ${(activeStep === index + 1) ? "active" : ""} ${activeStep > index + 1 ? "done" : ""}`}>
//               {step.component}
//             </StepContent>
//           </Step>
//         ))}
          
//       </Stepper>
      
//      </>
//   );
// }
export default function VerticalLinearStepper() {
  const {
    isFetchRegStepper,
    isSuccessgetRegStepper,
    getRegStepper,
    getRegActiveStep,
    isErrorgetRegStepper,
    getRegStepperData,
    errorMessagegetRegStepper,
    isSuccessRegStepper
  } = useSelector(RegistrationStepperSelector);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyle();

  useEffect(() => {
    if (isSuccessgetRegStepper) {
      setActiveStep(getRegActiveStep);
    }
  }, [isSuccessgetRegStepper, getRegActiveStep]);

  const steps = [
    { label: "Details of Mortgage Premises" },
    { label: "Lessee Details"},
    { label: "Authorised Person" },
    { label: "OC Details" },
    { label: "BUA Details"},
    { label: 'Sub Licensee Detail' },
    { label: 'Previous NOC Detail' },
    { label: "Upload Documents" },
    { label: "Payment Details" },
    { label: "Payment " }

  ];

  return (
    <div className={classes.root} >
      <Box className={classes.stepperContainer}>
        <Stepper activeStep={activeStep - 1} className={classes.stepper} connector={<ColorlibConnector />} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={StyledStepIcon}>
                {step.label}
              </StepLabel>
              <StepContent className={`${classes.stepContent} ${(activeStep === index + 1) ? "active" : ""} ${activeStep > index + 1 ? "done" : ""}`}>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
        {/* {steps[activeStep - 1] && steps[activeStep - 1].component} */}
      {/* <Box className={classes.contentContainer}>
        <MortgageForm/>
      </Box> */}
    </div>
  );
}
