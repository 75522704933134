import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { TextField, MenuItem, Button, Grid, Box, Typography, CircularProgress, Snackbar } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import AlertBox from '../../../atoms/AlertBox/AlertBox';
import { FileUploadIcon } from '../../../atoms/SvgIcons/SvgIcons';
import { useTranslation } from 'react-i18next';
import { initialPagesStyles } from '../../InitialPagesComponents/InitialPages.styles';
import { useDispatch, useSelector } from 'react-redux';
import { clearImageUrl, fileDataUpload, fileUploadSelector } from '../../../../redux/features/file/FileUploadSlice';
import { ImageSizes, SupportedFormats } from "../../../../utils/Common";
import Upload from "../../../../assets/Upload.svg";
import UploadedKyc from "../../../../assets/UploadedKyc.svg";
import { getPropertyDetails, getSummaryDetails, MortgagePropertyDetails, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave, RegistrationStepperSelector } from '../../../../redux/features/registration/registrationStepperSlice';
import { Autocomplete } from '@material-ui/lab';
import * as Yup from 'yup';

const MortgagePremisesDetails = (props) => {
  const { active, data } = props;

  const classes = initialPagesStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("InitialPageTrans");
  const [isImageA, setIsImageA] = useState(false);
  const [isImageB, setIsImageB] = useState(false);
  const [approvedPlanAUrl, setApprovedPlanAUrl] = useState('');
  const [approvedPlanBUrl, setApprovedPlanBUrl] = useState('');
  const [approvalPlanAPreview, setApprovalPlanAPreview] = useState(null);
  const [approvalPlanBPreview, setApprovalPlanBPreview] = useState(null);
  const [uploadingField, setUploadingField] = useState('');
  const fileInputRefA = useRef(null);
  const fileInputRefB = useRef(null);

  const [properties, setProperties] = useState([]);
  const {
    isFileFetching,
    isFileSuccess,
    imageUrl,
    isFileError,
    fileErrorMessage,
  } = useSelector(fileUploadSelector);
  const { isFetchingMortgageDetails,
    isSuccessMortageDetails, isFetchingProperty,
    isSuccessReqProperty,
    isSuccessResProperty,
    isErrorProperty,
    errorMessageProperty,
    isErrorMortageDetails,
    errorMsgMortageDetails,
    propertyData, } = useSelector(PmayNonPmaySelector)
  const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);

  const getInitialValue = (field) => {
    return summaryData ? summaryData[0]?.[field] : data?.[field] || '';
  };

  const initialValues = {
    premises_type: getInitialValue('premises_type'),
    property_id: getInitialValue('property_id'),
    pro_plot_no: getInitialValue('pro_plot_no'),
    pro_block_no: getInitialValue('pro_block_no'),
    pro_unit_no: getInitialValue('pro_unit_no'),
    pro_floor: getInitialValue('pro_floor'),
    pro_wing: getInitialValue('pro_wing'),
    property_area: getInitialValue('property_area'),
    pro_approved_plan_doc_a: '',
    pro_approved_plan_doc_b: '',
    property_name: getInitialValue('property_name'),
    property_code: getInitialValue('property_code'),
  };

  useEffect(() => {
    dispatch(getPropertyDetails());
    dispatch(getSummaryDetails());
    dispatch(clearImageUrl());
  }, [])

  useEffect(() => {
    if (isSuccessResProperty) {
      setProperties(propertyData); // Set the properties data fetched from API
    }
  }, [isSuccessResProperty, propertyData]);

  function hasImageExtension(url) {
    if (!url) {
      return false;
    }
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.tiff'];
    return imageExtensions.some(extension => url.toLowerCase().endsWith(extension));
  }

  // Usage


  useEffect(() => {
    if (isSuccessResSummary && summaryData) {
      const proApprovedPlanDocA = summaryData.DocumentDetails.pro_approved_plan_doc_a;
      const proApprovedPlanDocB = summaryData.DocumentDetails.pro_approved_plan_doc_b;
      // formik.setFieldValue( "pro_approved_plan_doc_a",proApprovedPlanDocA)
      // formik.setFieldValue( "pro_approved_plan_doc_b",proApprovedPlanDocB)
      if (hasImageExtension(proApprovedPlanDocA)) {
        setIsImageA(true)
        setApprovalPlanAPreview(proApprovedPlanDocA);
      } else {
        setIsImageA(false)
      }
      if (hasImageExtension(proApprovedPlanDocB)) {
        setIsImageB(true)
        setApprovalPlanBPreview(proApprovedPlanDocB);
      } else {
        setIsImageB(false)
      }


      setApprovalPlanBPreview(proApprovedPlanDocB);

    }

  }, [isSuccessResProperty, summaryData])

 
  const handlePropertyChange = (event, value) => {
    if (value) {
      formik.setValues({
        ...formik.values,
        property_id: value.id,
        pro_plot_no: value.property_plot_no,
        pro_floor: value.property_floor,
        pro_wing: value.property_wingno,
        pro_block_no: value.property_block_no,
        pro_unit_no: value.property_unit_no,
        property_area: value.property_area,
        premises_type: value.property_type,
        property_name: value.property_name,
        property_code: value.property_code,
      });
    
    }
  };

  const handlePropertyNameChange = (event, value) => {
    if (value) {
      formik.setValues({
        ...formik.values,
        property_id: value.id,
        pro_plot_no: value.property_plot_no,
        pro_floor: value.property_floor,
        pro_wing: value.property_wingno,
        pro_block_no: value.property_block_no,
        pro_unit_no: value.property_unit_no,
        property_area: value.property_area,
        premises_type: value.property_type,
        property_name: value.property_name,
        property_code: value.property_code,
      });
     
    }
  };

  const validationSchema = Yup.object({
    premises_type: Yup.string().required('Premises Type is required'),
    property_id: Yup.string().required('Property ID is required'),
    pro_plot_no: Yup.string().required('Plot No is required'),
    pro_block_no: Yup.string().required('Block No is required'),
    pro_unit_no: Yup.string().required('Unit No is required'),
    pro_floor: Yup.string().required('Floor No is required'),
    pro_wing: Yup.string().required('Wing is required'),
    property_area: Yup.string().required('Property Area is required'),
    property_name: Yup.string().required('Property Name is required'),
    property_code: Yup.string().required('Property Code is required'),
  });

  const formik = useFormik({
    validationSchema,
   
    initialValues,
    onSubmit: values => {
      // Handle form submission logic here
      const data = {
        ...values,

        pro_approved_plan_doc_a: approvedPlanAUrl,
        pro_approved_plan_doc_b: approvedPlanBUrl,
      }
      // console.log(data, "datattobesend");
      dispatch(MortgagePropertyDetails(data));

    },
  });

  const handleRadioChange = (event) => {
    // Update formik values with the selected radio button value
    formik.setFieldValue('premises_type', event.target.value);
  };

  useEffect(() => {
    if (isSuccessMortageDetails) {
      dispatch(clearImageUrl());
      dispatch(RegistrationStepperSave("2"))
    }
  }, [isSuccessMortageDetails, dispatch]);

  const onReUpload = (fieldName) => {
    if (fieldName === 'pro_approved_plan_doc_a' && fileInputRefA.current) {
      fileInputRefA.current.click();
    } else if (fieldName === 'pro_approved_plan_doc_b' && fileInputRefB.current) {
      fileInputRefB.current.click();
    }
  };

  useEffect(() => {
    if (isFileSuccess && imageUrl) {
      if (uploadingField === 'pro_approved_plan_doc_a') {
        setApprovedPlanAUrl(imageUrl);
        dispatch(clearImageUrl());
      } else if (uploadingField === 'pro_approved_plan_doc_b') {
        setApprovedPlanBUrl(imageUrl);
        console.log('PAN Card URL:', imageUrl);
        dispatch(clearImageUrl());
      }
      dispatch(clearImageUrl());
      setUploadingField('');
    }
  }, [isFileSuccess, imageUrl, uploadingField, dispatch]);

  const handleFileChange = (event, fieldName) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setUploadingField(fieldName);
      formik.setFieldValue(fieldName, file);
      const data = { doc: file, docName: fieldName };
      dispatch(fileDataUpload(data));
      if (fieldName === 'pro_approved_plan_doc_a') {
        if (SupportedFormats.DocsFormats.includes(file.type)) {
          switch (file.type) {
            case "image/jpeg":
            case "image/png":
              setIsImageA(true);
              break
            case "application/pdf":
            default:
              setIsImageA(false);
          }
        }
        setApprovalPlanAPreview(URL.createObjectURL(file));
      } else if (fieldName === 'pro_approved_plan_doc_b') {
        if (SupportedFormats.DocsFormats.includes(file.type)) {
          switch (file.type) {
            case "image/jpeg":
            case "image/png":
              setIsImageB(true);
              break
            case "application/pdf":
            default:
              setIsImageB(false);
          }
        }
        setApprovalPlanBPreview(URL.createObjectURL(file));
      }
      console.log(`File uploaded for ${fieldName}:`, file);
    }
  };

  const premisesTypeOptions = [
    { value: 'land_plot', label: 'Land / Plot' },
    { value: 'commercial', label: 'Commercial' },
    { value: 'residential', label: 'Residential' }
  ];

  return (

    <>
      {isErrorMortageDetails && (
        <AlertBox severity="error">{errorMsgMortageDetails}</AlertBox>
      )}
      {isFileError && (
        <AlertBox severity="error">{fileErrorMessage}</AlertBox>
      )}

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Premises Type</FormLabel>
              <RadioGroup row id="premises_type" name="premises_type" value={formik.values.premises_type||""} onChange={handleRadioChange}>
                {premisesTypeOptions.map(option => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}

                    control={<Radio color="primary" />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
              {formik.touched.premises_type && formik.errors.premises_type ? (
                <Typography color="error" variant="body2">{formik.errors.premises_type}</Typography>
              ) : null}
            </FormControl>
          </Grid>
      <Grid item xs={6}>
                  <Autocomplete
                    id="property_code"
                    options={properties}
                    getOptionLabel={(option) => option.property_code}
                    value={properties.find(prop => prop.property_code === formik.values.property_code) || null}
                    onChange={handlePropertyChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="property_code"
                        label="Property Code"
                        variant="outlined"
                        error={formik.touched.property_code && Boolean(formik.errors.property_code)}
                        helperText={formik.touched.property_code && formik.errors.property_code}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        margin='dense'
                        InputLabelProps={{ shrink: true }}
                    required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="property_name"
                    options={properties}
                    getOptionLabel={(option) => option.property_name}
                    value={properties.find(prop => prop.property_name === formik.values.property_name) || null}
                    onChange={handlePropertyNameChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="property_name"
                        label="Property Name"
                        variant="outlined"
                        error={formik.touched.property_name && Boolean(formik.errors.property_name)}
                        helperText={formik.touched.property_name && formik.errors.property_name}
                        onBlur={formik.handleBlur}
                       margin='dense'
                       InputLabelProps={{ shrink: true }}
                    required
                        onChange={formik.handleChange}
                      />
                    )}
                  />
                </Grid>


          <Grid item xs={6}>
            <TextField
              fullWidth
              id="pro_block_no"
              name="pro_block_no"
              label="Block No"
              value={formik.values.pro_block_no}
              onChange={formik.handleChange}
              margin="dense"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              required
              error={formik.touched.pro_block_no && Boolean(formik.errors.pro_block_no)}
              helperText={formik.touched.pro_block_no && formik.errors.pro_block_no}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="pro_plot_no"
              name="pro_plot_no"
              label="Plot No"
              required
              InputLabelProps={{ shrink: true }}
              value={formik.values.pro_plot_no}
              onChange={formik.handleChange}
              margin="dense"
              variant="outlined"
              error={formik.touched.pro_plot_no && Boolean(formik.errors.pro_plot_no)}
              helperText={formik.touched.pro_plot_no && formik.errors.pro_plot_no}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="pro_floor"
              name="pro_floor"
              label="Floor No"
              value={formik.values.pro_floor}
              onChange={formik.handleChange}
              required
              margin="dense"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              error={formik.touched.pro_floor && Boolean(formik.errors.pro_floor)}
              helperText={formik.touched.pro_floor && formik.errors.pro_floor}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="pro_unit_no"
              name="pro_unit_no"
              label="Unit No"
              required
              InputLabelProps={{ shrink: true }}
              value={formik.values.pro_unit_no}
              onChange={formik.handleChange}
              margin="dense"
              variant="outlined"
              error={formik.touched.pro_unit_no && Boolean(formik.errors.pro_unit_no)}
              helperText={formik.touched.pro_unit_no && formik.errors.pro_unit_no}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="pro_wing"
              name="pro_wing"
              label="Wing"
              required
              value={formik.values.pro_wing}
              onChange={formik.handleChange}
              margin="dense"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              error={formik.touched.pro_wing && Boolean(formik.errors.pro_wing)}
              helperText={formik.touched.pro_wing && formik.errors.pro_wing}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="property_area"
              name="property_area"
              label="Property Area"
              fullWidth
              required
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              value={formik.values.property_area}
              onChange={formik.handleChange}
              error={formik.touched.property_area && Boolean(formik.errors.property_area)}
              helperText={formik.touched.property_area && formik.errors.property_area}
            />
          </Grid>
          <Grid item xs={6}>
            <input
              ref={fileInputRefA}
              style={{ display: 'none' }}
              type="file"
              accept="image/*, application/pdf"
              id="pro_approved_plan_doc_a"
              name="pro_approved_plan_doc_a"
              onChange={(event) => handleFileChange(event, 'pro_approved_plan_doc_a')}
            />
            <TextField
              fullWidth
              label='Upload Approval Plan (Front Side)'
              variant="outlined"
              margin="dense"
              disabled
              //onClick={() => fileInputRefA.current.click()}
              value={formik.values.pro_approved_plan_doc_a.name || ''}
              InputProps={{


                endAdornment: (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => fileInputRefA.current.click()}
                  >
                    Browse
                  </Button>
                ),
              }}
            />
            {(approvalPlanAPreview) && (
              <Box>
                <Typography className={classes.kycCaption} variant="subtitle1">
                  Preview Approval Plan Front Side
                </Typography>
                {isImageA ? (
                  <Box className={classes.panCardPreviewCard}>
                    <img
                      className={classes.panPreviewImg}
                      src={approvalPlanAPreview}
                      alt="uploaded successfully"
                    />
                  </Box>
                ) : (
                  <Box textAlign="center" className={classes.panCardPreviewCard}>
                    <img className={classes.panPreviewImg} src={UploadedKyc} alt="uploaded successfully" />
                    <Typography className={classes.kycCaption}>
                      {t("kycBank.formControl.upload.success")}
                    </Typography>
                  </Box>
                )}
                <Box textAlign="left" marginTop={2}>
                  <Typography className={classes.kycCaption} variant="subtitle1">
                    Uploaded Approval Plan Front Side
                  </Typography>
                </Box>
                <Box className={classes.fileViewArea}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="auto">
                      <FileUploadIcon color="primary" />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography variant="body2" className={classes.fileUrlPreview}>
                        {approvalPlanAPreview || "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        variant="text"
                        size="small"
                        color="primary"
                        onClick={() => onReUpload('pro_approved_plan_doc_a')}
                      >
                        {t("kycBank.formControl.upload.reupload")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Grid>

          <Grid item xs={6}>
            <input
              ref={fileInputRefB}
              style={{ display: 'none' }}
              type="file"
              accept="image/*, application/pdf"
              id="pro_approved_plan_doc_b"
              name="pro_approved_plan_doc_b"
              onChange={(event) => handleFileChange(event, 'pro_approved_plan_doc_b')}
            />
            <TextField
              fullWidth
              label='Upload Approval Plan (Back Side)'
              variant="outlined"
              margin="dense"
              disabled
              //onClick={() => fileInputRefB.current.click()}
              value={formik.values.pro_approved_plan_doc_b.name || ''}
              InputProps={{
                endAdornment: (
                  <Button
                    variant="contained"
                    size="small"

                    color="primary"
                    onClick={() => fileInputRefB.current.click()}
                  >
                    Browse
                  </Button>
                ),
              }}
            />
            {approvalPlanBPreview && (
              <Box>
                <Typography className={classes.kycCaption} variant="subtitle1">
                  Preview Approval Plan Back Side
                </Typography>
                {isImageB ? (
                  <Box className={classes.panCardPreviewCard}>
                    <img
                      className={classes.panPreviewImg}
                      src={approvalPlanBPreview}
                      alt="uploaded successfully"
                    />
                  </Box>
                ) : (
                  <Box textAlign="center" className={classes.panCardPreviewCard}>
                    <img className={classes.panPreviewImg} src={UploadedKyc} alt="uploaded successfully" />
                    <Typography className={classes.kycCaption}>
                      {t("kycBank.formControl.upload.success")}
                    </Typography>
                  </Box>
                )}
                <Box textAlign="left" marginTop={2}>
                  <Typography className={classes.kycCaption} variant="subtitle1">
                    Uploaded Approval Plan Back Side
                  </Typography>
                </Box>
                <Box className={classes.fileViewArea}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="auto">
                      <FileUploadIcon color="primary" />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography variant="body2" className={classes.fileUrlPreview}>
                        {approvalPlanBPreview || "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        variant="text"
                        size="small"
                        color="primary"
                        onClick={() => onReUpload('pro_approved_plan_doc_b')}
                      >
                        {t("kycBank.formControl.upload.reupload")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={isFetchingMortgageDetails}
          >
            {isFetchingMortgageDetails && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
            {isFetchingMortgageDetails ? 'Saving...' : 'Save & Continue'}
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default MortgagePremisesDetails;

