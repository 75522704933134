import React from "react";

import Layout from "../Layout/Layout";
import Payment from "../../organisms/RegistrationComponents/Registrationform/Payment";


const PaymentPage = () => {
  return (
    <Layout isStepper={false} noScrollIs={true} >
      {/* <Dashboard /> */}
      {/* <SummaryDetails/> */}
      <Payment/>
    </Layout>
  );
};

export default PaymentPage;