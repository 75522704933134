import React, { useEffect } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import FormControl from '../../../molecules/FormControl/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
import { EventContactPerson, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';

const validationSchema = Yup.object().shape({
  contactPersonName: Yup.string().required('Contact Person Name is required'),
  designation: Yup.string().required('Designation is required'),
  mobileNumber: Yup.string().required('Mobile Number is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

const ContactPersonDetails = ({data}) => {
 
  const { isSuccessContact,isFetchingContact } = useSelector(PmayNonPmaySelector)


    const dispatch = useDispatch();
    useEffect(() => {
      if (isSuccessContact) {
          dispatch(RegistrationStepperSave("6"))
      }
  }, [isSuccessContact]);
  const initialValues = {
    contactPersonName:data?.contactPersonName ||'',
    designation: data?.contactPersonDesignation || '',
    mobileNumber: data?.contactPersonMobileNo ||'',
    email: data?.contactPersonEmail ||'',
  };

  const handleSubmit = (values, { setSubmitting }) => {
    // Handle form submission here, e.g., API call
    console.log(values);
    setSubmitting(false);
    dispatch(EventContactPerson(values));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
              <FormControl
                  control="input"
                  variant="outlined"
                  label="Contact Person Name"
                  placeholder="Contact Person Name"
                  name="contactPersonName"
                  type="text"
                  id="contactPersonName"
                  required
                  margin="dense"
                  helperText={<ErrorMessage name="contactPersonName" />}
                />
              
              </Grid>
              <Grid item xs={12} sm={6}>
              <FormControl
                  control="input"
                  variant="outlined"
                  label="Designation"
                  placeholder="Designation"
                  name="designation"
                  type="text"
                  id="designation"
                  required
                  margin="dense"
                  helperText={<ErrorMessage name="designation" />}
                />
                
              </Grid>
              <Grid item xs={12} sm={6}>
              <FormControl
                  control="input"
                  variant="outlined"
                  label="Mobile Number"
                  placeholder="Mobile Number"
                  name="mobileNumber"
                  type="text"
                  id="mobileNumber"
                  required
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                
                 <FormControl
                  control="input"
                  variant="outlined"
                  label="Email"
                  placeholder="Email"
                  name="email"
                  type="text"
                  id="email"
                  required
                  margin="dense"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ContactPersonDetails;
