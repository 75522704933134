import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Divider, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import AcUnitOutlinedIcon from '@material-ui/icons/AcUnitOutlined';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import {
  TotalApplicationsIcon, TotalSubmittedIcon,
  ApplicationsInProgressIcon
} from "../../../atoms/SvgIcons/SvgIcons";

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    color: "#0F2940",
    fontWeight: "normal",
    lineHeight: "normal",
    fontSize: "0.8rem",
    marginBottom: theme.spacing(2)
  },
  titleDot: {
    backgroundColor: "#0F2940",
    display: "inline-block",
    width: 10,
    height: 10,
    marginRight: theme.spacing(1.2)
  },
  amountView: {
    color: "#0F2940",
    fontWeight: "bold",
    lineHeight: "normal",
    fontSize: "2rem",
  },
  iconView: {
    width: 40,
    height: 40,
  },
  dropdownBtnView: {
    marginTop: theme.spacing(1),
    textAlign: "right"
  },
  popoverContainer: {
    "& .MuiPopover-paper": {
      minWidth: 400
    }
  },
  popoverTitle: {
    color: "#0F2940",
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    lineHeight: 1
  },
  applicationList: {
    width: "100%"
  },
  /* applicationTxt: {
    color: "#65707D",
    fontWeight: "500",
    fontSize: "1rem",
    "& span": {
      backgroundColor: "#65707D",
      borderRadius: "50%",
      display: "inline-block",
      width: 5,
      height: 5,
      verticalAlign: "middle",
      marginRight: theme.spacing(1)
    },
    "&.link": {
      color: "#007AE7",
      cursor: "pointer",
      textAlign: "right"
    }
  } */
}));

const ApplicationsCountCard = (props) => {
  const { cardData } = props;
  const classes = useStyles();
  const { t } = useTranslation("ManagerDashboardPageTrans");
  const [boxColor, setBoxColor] = useState("#0F2940");
  const [boxIconView, setBoxIconView] = useState(<TotalApplicationsIcon className={classes.iconView} />);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (cardData.cardType == "submitted") {
      setBoxColor("#007AE7");
      setBoxIconView(<TotalSubmittedIcon className={classes.iconView} />);
    }
    if (cardData.cardType == "totalEarnings") {
      setBoxColor("#009B19");
      setBoxIconView(<ApplicationsInProgressIcon className={classes.iconView} />);
    }
  }, []);

  return (
    <Box component={Paper} p={1} height="100%">
      <Typography className={classes.cardTitle}>
        <span className={classes.titleDot} style={{ backgroundColor: boxColor }}></span>
        {cardData.title}
      </Typography>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Typography className={classes.amountView} style={{ color: boxColor }}>{cardData.count}</Typography>
        </Grid>
        <Grid item>
          {boxIconView}
        </Grid>
      </Grid>
      <Box className={classes.dropdownBtnView}>
        {cardData.cardType == "submitted" &&
          <Button aria-describedby={id} onClick={handleClick} size="small" endIcon={<KeyboardArrowDownOutlinedIcon />}>{t("appCardGroup.viewDetailsBtnTxt")}</Button>
        }
        {cardData.cardType == "totalEarnings" &&
          <Button size="small">{t("appCardGroup.showMoreBtnTxt")}</Button>
        }
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={classes.popoverContainer}
        >
          <Box padding={1.5}>
            <Typography className={classes.popoverTitle}>{t("appCardGroup.popover.title")}</Typography>
            <table className={classes.applicationList}>
              <tbody>
                <tr>
                  <td><Typography className={classes.applicationTxt}><span></span> Payment Completed</Typography></td>
                  <td><Typography className={classes.applicationTxt}><strong>100%</strong></Typography></td>
                  <td><Typography className={`${classes.applicationTxt} link`}>{cardData.count} Applications</Typography></td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default ApplicationsCountCard;
