// import React, { useState } from 'react';
// import './Period.css';

// function PeriodDetails() {
//   const [formData, setFormData] = useState({
//     periods: [
//       { purpose: 'Set up', from: '', to: '', days: '', area: '', selected: true },
//       { purpose: 'Exhibition / Event', from: '', to: '', days: '', area: '', selected: true },
//       { purpose: 'Parking', from: '', to: '', days: '', area: '', selected: true },
//       { purpose: 'Dismantle', from: '', to: '', days: '', area: '', selected: true },
//     ],
//   });

//   const calculateDays = (from, to) => {
//     if (!from || !to) return '';
//     const fromDate = new Date(from);
//     const toDate = new Date(to);
//     const timeDiff = toDate - fromDate;
//     const daysDiff = timeDiff / (1000 * 60 * 60 * 24) + 1; // Adding 1 to include both start and end date
//     return daysDiff > 0 ? daysDiff : '';
//   };

//   const handleChange = (index, field, value) => {
//     const updatedPeriods = formData.periods.map((period, i) => {
//       if (i === index) {
//         const updatedPeriod = { ...period, [field]: value };
//         if (field === 'from' || field === 'to') {
//           updatedPeriod.days = calculateDays(updatedPeriod.from, updatedPeriod.to);
//         }
//         return updatedPeriod;
//       }
//       return period;
//     });
//     setFormData({ periods: updatedPeriods });
//   };

//   const handleCheckboxChange = (index) => {
//     const updatedPeriods = formData.periods.map((period, i) => 
//       i === index ? { ...period, selected: !period.selected } : period
//     );
//     setFormData({ periods: updatedPeriods });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     // Handle form submission
//     console.log(formData);
//   };

//   return (
//     <div className="App">
//       <form onSubmit={handleSubmit}>
//         <table>
//           <thead>
//             <tr>
//               <th>Select</th>
//               <th>Purpose</th>
//               <th>From</th>
//               <th>To</th>
//               <th>No.of Days</th>
//               <th>Area (Sq.mt.)</th>
//             </tr>
//           </thead>
//           <tbody>
//             {formData.periods.map((period, index) => (
//               <tr key={index} className={period.selected ? '' : 'disabled'}>
//                 <td>
//                   <input 
//                     type="checkbox" 
//                     checked={period.selected} 
//                     onChange={() => handleCheckboxChange(index)} 
//                   />
//                 </td>
//                 <td>{period.purpose}</td>
//                 <td>
//                   <input 
//                     type="date" 
//                     value={period.from} 
//                     onChange={(e) => handleChange(index, 'from', e.target.value)} 
//                     disabled={!period.selected}
//                   />
//                 </td>
//                 <td>
//                   <input 
//                     type="date" 
//                     value={period.to} 
//                     onChange={(e) => handleChange(index, 'to', e.target.value)} 
//                     disabled={!period.selected}
//                   />
//                 </td>
//                 <td className="small-column">
//                   <input 
//                     type="number" 
//                     value={period.days} 
//                     readOnly 
//                   />
//                 </td>
//                 <td className="small-column">
//                   <input 
//                     type="number" 
//                     value={period.area} 
//                     onChange={(e) => handleChange(index, 'area', e.target.value)} 
//                     disabled={!period.selected}
//                   />
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   );
// }

// export default PeriodDetails;


import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { EventPeriod, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  smallColumn: {
    maxWidth: 120,
  },
});

function PeriodDetails({data}) {
  const classes = useStyles();
  // const [formData, setFormData] = useState({
  //   periods: [
  //     { id: 1, purpose: 'Set up', from: '', to: '', days: '', area: '', selected: true },
  //     { id: 2, purpose: 'Exhibition / Event', from: '', to: '', days: '', area: '', selected: true },
  //     { id: 3, purpose: 'Parking', from: '', to: '', days: '', area: '', selected: true },
  //     { id: 4, purpose: 'Dismantle', from: '', to: '', days: '', area: '', selected: true },
  //   ],
  // });

  const initialData = data?.EventPeriod && JSON.parse(data?.EventPeriod)|| [
    { id: 1, purpose: 'Set up', from: '', to: '', days: '', area: '', selected: true },
    { id: 2, purpose: 'Exhibition / Event', from: '', to: '', days: '', area: '', selected: true },
    { id: 3, purpose: 'Parking', from: '', to: '', days: '', area: '', selected: true },
    { id: 4, purpose: 'Dismantle', from: '', to: '', days: '', area: '', selected: true },
  ];
  const [formData, setFormData] = useState({ periods: initialData });
  const {  isSuccessEventPeriod , isFetchingEventPeriod} = useSelector(PmayNonPmaySelector)


    const dispatch = useDispatch();
    useEffect(() => {
      if ( isSuccessEventPeriod) {
          dispatch(RegistrationStepperSave("5"))
      }
  }, [ isSuccessEventPeriod]);
  const calculateDays = (from, to) => {
    if (!from || !to) return '';
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const timeDiff = toDate - fromDate;
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24) + 1; // Adding 1 to include both start and end date
    return daysDiff > 0 ? daysDiff : '';
  };

  const handleChange = (id, field, value) => {
    const updatedPeriods = formData.periods.map((period) => {
      if (period.id === id) {
        const updatedPeriod = { ...period, [field]: value };
        if (field === 'from' || field === 'to') {
          updatedPeriod.days = calculateDays(updatedPeriod.from, updatedPeriod.to);
        }
        return updatedPeriod;
      }
      return period;
    });
    setFormData({ periods: updatedPeriods });
  };

  const handleCheckboxChange = (id) => {
    const updatedPeriods = formData.periods.map((period) =>
      period.id === id ? { ...period, selected: !period.selected } : period
    );
    setFormData({ periods: updatedPeriods });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
    console.log(formData);
    dispatch(EventPeriod(formData));
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Select</StyledTableCell>
                <StyledTableCell>Purpose</StyledTableCell>
                <StyledTableCell>From</StyledTableCell>
                <StyledTableCell>To</StyledTableCell>
                <StyledTableCell>No. of Days</StyledTableCell>
                <StyledTableCell>Area (Sq.mt.)</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.periods.map((period) => (
                <StyledTableRow key={period.id}>
                  <StyledTableCell>
                    <Checkbox
                      checked={period.selected}
                      onChange={() => handleCheckboxChange(period.id)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{period.purpose}</StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      type="date"
                      value={period.from}
                      onChange={(e) => handleChange(period.id, 'from', e.target.value)}
                      disabled={!period.selected}
                      className={classes.smallColumn}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      type="date"
                      value={period.to}
                      onChange={(e) => handleChange(period.id, 'to', e.target.value)}
                      disabled={!period.selected}
                      className={classes.smallColumn}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      type="number"
                      value={period.days}
                      readOnly
                      className={classes.smallColumn}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      type="number"
                      value={period.area}
                      onChange={(e) => handleChange(period.id, 'area', e.target.value)}
                      disabled={!period.selected}
                      className={classes.smallColumn}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2}><Button type="submit" variant="contained" color="primary">
          Submit
        </Button></Box>
      </form>
    </div>
  );
}

export default PeriodDetails;
