import React from 'react'
import PaymentSuccessLayout from '../Layout/PaymentSuccessLayout'
import AddCoApplicantPaymentSuccessful from '../../organisms/ProfilePageComponents/OtherRequestPageComponents/EditCoApplicantPageComponent/AddCoApplicantPaymentSuccessfulComponents/AddCoApplicantPaymentSuccessful'

const AddCoApplicantPaymentSuccessPage = () => {
  return (
    <PaymentSuccessLayout>
      <AddCoApplicantPaymentSuccessful />
    </PaymentSuccessLayout>
  )
}

export default AddCoApplicantPaymentSuccessPage