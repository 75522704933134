import React from "react";
// import ViewAllNotifications from "../../organisms/ViewAllNotificationsPagesComponents/ViewAllNotifications/ViewAllNotifications";
// import NotificationPageLayout from "../Layout/NotificationPageLayout";

const PageNotFoundPage = () => {
  return (
    <h1>Page Not Found!</h1>
  );
};

export default PageNotFoundPage;
