

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Grid, Button, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fileDataUpload, fileUploadSelector, clearImageUrl } from '../../../../redux/features/file/FileUploadSlice';
import { AuthorizedLisionDetailed, getSummaryDetails, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
import AlertBox from '../../../atoms/AlertBox/AlertBox';



const validationSchema = Yup.object().shape({
    auth_person_name: Yup.string().required('Required'),
    auth_person_designation: Yup.string().required('Required'),
    auth_person_mobile: Yup.string().required('Required').matches(/^[0-9]{10}$/, 'Invalid mobile number'),
    //auth_person_aadhar_no: Yup.string().matches(/^[0-9]{14}$/, 'Aadhar number must be 12 digits').required('Aadhar number is required'),
    auth_person_pan_no: Yup.string().required('Required').matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN number format'),
    company_name: Yup.string().required('Required'),
});

const emptyValidationSchema = Yup.object().shape({});

const LiaisonPersonForm = (props) => {
    const { active, data } = props;
   
    const [aadharCardUrl, setAadharCardUrl] = useState('');
    const [panCardUrl, setPanCardUrl] = useState('');
    const [aadharCardPreview, setAadharCardPreview] = useState(null);
    const [panCardPreview, setPanCardPreview] = useState(null);
    const [uploadingField, setUploadingField] = useState('');
    const dispatch = useDispatch();
    const { isFileFetching, isFileSuccess, imageUrl, isFileError, fileErrorMessage } = useSelector(fileUploadSelector);
    const { isFetchingAuthorizedLisionDetail, isSuccessAuthorizedLisionDetail ,isErrorAuthorizedLisionDetail,errorMsgAuthorizedLisionDetail} = useSelector(PmayNonPmaySelector);
    const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
   
    const getInitialValue = (field) => {
        return summaryData ? summaryData[0]?.[field] : data?.[field] || '';
      };

    const defaultValues = {
        auth_person_name: getInitialValue('auth_person_name'),
        auth_person_designation: getInitialValue('auth_person_designation'),
        auth_person_mobile: getInitialValue('auth_person_mobile'),
        auth_person_aadhar_no:  getInitialValue('auth_person_aadhar_no'),
        auth_person_pan_no: getInitialValue('auth_person_pan_no'),
        auth_person_aadhar_card_doc: "",
        auth_person_pan_card_doc: "",
        lessee:  getInitialValue('lessee'),
        company_name: getInitialValue('company_name'),
    };

    const initialValues = { ...defaultValues,  };
    const [loanAvailed, setLoanAvailed] = useState(initialValues.lessee === 'yes' ? 'yes' : 'no');
    const formik = useFormik({
        initialValues,
        validationSchema: loanAvailed === 'yes' ? emptyValidationSchema : validationSchema,
        onSubmit: values => {
            if(formik.values.lessee ==='yes'){
                const data = {
                    lessee:formik.values.lessee
                };    
                
           //console.log('Form data on leese yes:', data);
            dispatch(AuthorizedLisionDetailed(data));
            }
            else {
            const data = {
                ...values,
                auth_person_aadhar_card_doc: aadharCardUrl,
                auth_person_pan_card_doc: panCardUrl
            };

           //console.log('Form data on submit leese no:', data);
         dispatch(AuthorizedLisionDetailed(data));
            }
        },
    });
   
    useEffect(() => {
        dispatch(getSummaryDetails());
        dispatch(clearImageUrl());
    }, [])
   
    function hasImageExtension(url) {
        if (!url) {
            return false;
          }
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.tiff'];
        return imageExtensions.some(extension => url.toLowerCase().endsWith(extension));
      }
      
      // Usage
      
      
    useEffect(()=>{
      if(isSuccessResSummary && summaryData)
      {
        const proApprovedPlanDocA = summaryData?.DocumentDetails?.auth_person_aadhar_card_doc;
        const proApprovedPlanDocB = summaryData?.DocumentDetails?.auth_person_pan_card_doc;
        formik.setFieldValue( "auth_person_aadhar_card_doc",proApprovedPlanDocA)
        formik.setFieldValue( "auth_person_pan_card_doc",proApprovedPlanDocB)
       
        if (hasImageExtension(proApprovedPlanDocA)) {
         setAadharCardPreview(proApprovedPlanDocA)
        
        } else {
            setAadharCardPreview("")
        }
       
        if (hasImageExtension(proApprovedPlanDocB)) {
            setPanCardPreview(proApprovedPlanDocB)
           
           } else {
               setPanCardPreview("")
           }
    
      
      }
    
    },[isSuccessResSummary,summaryData])
  
  
    useEffect(() => {
        if (isSuccessAuthorizedLisionDetail) {
            dispatch(clearImageUrl());
            dispatch(RegistrationStepperSave("4"));
        }
    }, [isSuccessAuthorizedLisionDetail, dispatch]);

    useEffect(() => {
        if (isFileSuccess && imageUrl) {
            if (uploadingField === 'auth_person_aadhar_card_doc') {
                setAadharCardUrl(imageUrl);
                formik.setFieldValue('auth_person_aadhar_card_doc', imageUrl);
                console.log('Aadhar Card URL:', imageUrl);
            } else if (uploadingField === 'auth_person_pan_card_doc') {
                setPanCardUrl(imageUrl);
                formik.setFieldValue('auth_person_pan_card_doc', imageUrl);
                console.log('PAN Card URL:', imageUrl);
            }
            dispatch(clearImageUrl());
            setUploadingField('');
        }
    }, [isFileSuccess, imageUrl, uploadingField, formik, dispatch]);

    const handleFileChange = (event, fieldName) => {
        const file = event.currentTarget.files[0];
        if (file) {
            setUploadingField(fieldName);
            formik.setFieldValue(fieldName, file);
            const data = { doc: file, docName: fieldName };
            dispatch(fileDataUpload(data));
            if (fieldName === 'auth_person_aadhar_card_doc') {
                setAadharCardPreview(URL.createObjectURL(file));
            } else if (fieldName === 'auth_person_pan_card_doc') {
                setPanCardPreview(URL.createObjectURL(file));
            }
            console.log(`File uploaded for ${fieldName}:`, file);
        }
    };

   
    const handleLoanAvailedChange = (event) => {
        setLoanAvailed(event.target.value);
        formik.setFieldValue('lessee', event.target.value);
        showBankDetails(event.target.value === 'yes');
    };

    const showBankDetails = (show) => {
        const element = document.getElementById('namemorgae');
        if (element) {
            element.style.display = show ? 'block' : 'none';
        }
    };

    return (
        <>
         {isErrorAuthorizedLisionDetail && (
                  <AlertBox severity="error">{errorMsgAuthorizedLisionDetail}</AlertBox>
                )}
                  { isFileError && (
                  <AlertBox severity="error">{fileErrorMessage}</AlertBox>
                )}
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel component="legend">Are You Lessee ?</FormLabel>
                    <RadioGroup
                        row
                        id='lessee'
                        aria-label="lessee"
                        name="lessee"
                        value={loanAvailed}
                        onChange={handleLoanAvailedChange}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                <Grid item xs={12} id="namemorgae" style={{ display: loanAvailed === 'no' ? 'block' : 'none' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="auth_person_name"
                                name="auth_person_name"
                                label="Person Name"
                                value={formik.values.auth_person_name}
                                onChange={formik.handleChange}
                                variant="outlined"
                                margin="dense"
                                required={loanAvailed === 'yes' ? false:true}
                                error={formik.touched.auth_person_name && Boolean(formik.errors.auth_person_name)}
                                helperText={formik.touched.auth_person_name && formik.errors.auth_person_name}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="auth_person_aadhar_no"
                                name="auth_person_aadhar_no"
                                label="Aadhar No"
                                value={formik.values.auth_person_aadhar_no}
                                required={loanAvailed === 'yes' ? false:true}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(/\D/g, "").split(/(?:([\d]{4}))/g).filter(s => s.length > 0).join("-");
                                }}
                                inputProps={{
                                  maxLength: 14,
                                }}
                              
                                onChange={formik.handleChange}
                                variant="outlined"
                                margin="dense"
                                // error={formik.errors.auth_person_aadhar_no}
                                // helperText={formik.touched.auth_person_aadhar_no && formik.errors.auth_person_aadhar_no}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="auth_person_designation"
                                name="auth_person_designation"
                                label="Designation"
                                value={formik.values.auth_person_designation}
                                onChange={formik.handleChange}
                                variant="outlined"
                                margin="dense"
                                required={loanAvailed === 'yes' ? false:true}
                                error={formik.touched.auth_person_designation && Boolean(formik.errors.auth_person_designation)}
                                helperText={formik.touched.auth_person_designation && formik.errors.auth_person_designation}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                accept="image/*, application/pdf"
                                id="auth_person_aadhar_card_doc"
                                name="auth_person_aadhar_card_doc"
                                type="file"
                                label='Upload Aadhar Card (Both Side)'
                                variant="outlined"
                                margin="dense"
                                
                                onChange={(event) => handleFileChange(event, 'auth_person_aadhar_card_doc')}
                                onBlur={formik.handleBlur}
                                InputLabelProps={{ shrink: true }}
                            />
                            {aadharCardPreview && (
                                <img src={aadharCardPreview} alt="Aadhar Card Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="auth_person_mobile"
                                name="auth_person_mobile"
                                label="Mobile No"
                                value={formik.values.auth_person_mobile}
                                onChange={formik.handleChange}
                                variant="outlined"
                                type='numeric'
                                margin="dense"
                                inputProps={{
                                    maxLength: 10,
                                  }}
                                  required={loanAvailed === 'yes' ? false:true}
                                error={formik.touched.auth_person_mobile && Boolean(formik.errors.auth_person_mobile)}
                                helperText={formik.touched.auth_person_mobile && formik.errors.auth_person_mobile}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="auth_person_pan_no"
                                name="auth_person_pan_no"
                                label="PAN No"
                                required={loanAvailed === 'yes' ? false:true}
                        inputProps={{
                          maxLength: 10,
                        }}
                       
                        onInput={(e) =>
                          (e.target.value = ("" + e.target.value).toUpperCase())
                        }
                                value={formik.values.auth_person_pan_no}
                                onChange={formik.handleChange}
                                variant="outlined"
                                margin="dense"
                                error={formik.touched.auth_person_pan_no && Boolean(formik.errors.auth_person_pan_no)}
                                helperText={formik.touched.auth_person_pan_no && formik.errors.auth_person_pan_no}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        id="company_name"
                        name="company_name"
                        label="Company Name"
                        placeholder="Company Name"
                        variant="outlined"
                        margin="dense"
                        required={loanAvailed === 'yes' ? false:true}
                        value={formik.values.company_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                        helperText={formik.touched.company_name && formik.errors.company_name}
                    />
                </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label='Upload PAN Card (Both Sides)'
                                accept="image/*, application/pdf"
                                id="auth_person_pan_card_doc"
                                name="auth_person_pan_card_doc"
                                type="file"
                                variant="outlined"
                                margin="dense"
                                onChange={(event) => handleFileChange(event, 'auth_person_pan_card_doc')}
                                onBlur={formik.handleBlur}
                                InputLabelProps={{ shrink: true }}
                            />
                            {panCardPreview && (
                                <img src={panCardPreview} alt="PAN Card Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isFetchingAuthorizedLisionDetail}
        >
          {isFetchingAuthorizedLisionDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
          {isFetchingAuthorizedLisionDetail ? 'Saving...' : 'Save & Continue'}
        </Button>
      </Grid>
        </form>
        </>
    );
};

export default LiaisonPersonForm;
