import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";

import mmrda from "../../../../assets/mmrda.png"; // Replace with the path to your logo
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  Paymentformapi,
  PmayNonPmaySelector,
  clearPaymentDetails,
  getPaymentDetail,
  getSummaryDetails,
} from "../../../../redux/features/pmayNonPmay/pmayNonPmaySlice";
import Billdesk from "../../../../assets/BillDeskLogo.png";
import { withStyles, makeStyles, styled } from "@material-ui/core/styles";
import { Chip, Divider, IconButton, Switch, Tooltip } from "@material-ui/core";
import Stack from '@mui/material/Stack';
import HorizontalLinearStepper from "../Stepper/HorizontalLinearStepper";
import { clearRegistrationStepperData, getRegistrationProgress, RegistrationStepperSelector } from "../../../../redux/features/registration/registrationStepperSlice";
import FormCard from "../../../molecules/Cards/FormCard/FormCard";

const PaymentForm = () => {
  const history = useHistory();

  const StyledTableCell = withStyles((theme) => ({
    head: {
      background: "#15489f",
      color: "white !important",
    },
  
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const GradientChip = withStyles({
    root: {
      background: "linear-gradient(296deg, #00a74a, #15489f)",
      color: "#fff",
      "& .MuiChip-label": {
        color: "#fff",
      },
    },
  })(Chip);






  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [penaltyAdded, setPenaltyAdded] = useState(true);
  const [isRadioChecked, setIsRadioChecked] = useState(false);

  const dispatch = useDispatch();
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    rightAlign: {
      textAlign: 'right',
  },
  });
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push("/payment");

    // dispatch(Paymentformapi(submissionData));
  };

    const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
    const [summary, setSummary] = useState({});
    const [payment, setPayment] = useState({});




  useEffect(() => {
    dispatch(getSummaryDetails());
}, [dispatch]);



  
  const initialFormData = {
    premises_type: "commercial",
    property_id: "4",
    pro_plot_no: "6345",
    pro_block_no: "BKCCOMGC57590",
    pro_unit_no: "3423",
    pro_floor: "4",
    pro_wing: "23",
    pro_area_sqmr: "234",
    pro_approved_plan_doc_a: "https://restdemo.growthifye.in/applicationservice/./uploads/morg_noc/135/documents_135_pro_approved_plan_doc_a_135proapprovedplandoca.pdf",
    pro_approved_plan_doc_b: "https://restdemo.growthifye.in/applicationservice/./uploads/morg_noc/135/documents_135_pro_approved_plan_doc_b_135proapprovedplandocb.jpg",
    property_name: "Shree Naman Hotels Pvt. Ltd.",
    lessee_name: "pavan",
    sub_lessee_name: "ramesh",
    lessee_society_form: "yes",
    lessee_certificate_no: "10000000021",
    lessee_share_certificate_doc: "https://restdemo.growthifye.in/applicationservice/./uploads/morg_noc/135/documents_135_lessee_share_certificate_doc_135lesseesharecertificatedoc.jpg",
    lessee:"no",
    auth_person_name: "jame",
    auth_person_designation: "sdsdsada",
    auth_person_mobile: "sdsadsadsasdasd",
    auth_person_aadhar_no: "sdsfsafsadfsa",
    auth_person_pan_no: "sdsds",
    auth_person_aadhar_card_doc: "https://restdemo.growthifye.in/applicationservice/./uploads/morg_noc/135/documents_135_auth_person_aadhar_card_doc_135authpersonaadharcarddoc.jpg",
    auth_person_pan_card_doc: "https://restdemo.growthifye.in/applicationservice/./uploads/morg_noc/135/documents_135_auth_person_pan_card_doc_135authpersonpancarddoc.jpg",
    

    original_bua:"yes",
    original_total_bua:"12",
    original_bua_already:"13",
    original_bua_now:"13",
    original_bua_bal:"13",
    addl_bua:"yes",
    addl_total_bua:"13",
    addl_bua_already:"13",
    addl_bua_now:"13",
    addl_bua_bal:"13",
    bank_name: "hdfc bank",
    bank_branch_name: "mumbai",
    bank_ifsc_code: "hdfc0043434",
    bank_address:"belapur",

    mode_of_assignment: "Any Other",
    loan_sanction_letter_doc: "https://restdemo.growthifye.in/applicationservice/./uploads/morg_noc/135/documents_135_loan_sanction_letter_doc_135loansanctionletterdoc.png",
    mode_of_assignment_other:"new",

  pre_noc_letter_no:"23456523",
  pre_noc_date:"2024-07-09",
  pre_noc_doc_reg:"Sub Registrar Office",
  pre_noc_reg_no:"23r453",
  pre_noc_letter_doc:"https://restdemo.growthifye.in/applicationservice/./uploads/morg_noc/135/documents_135_pre_noc_letter_doc_135prenocletterdoc.jpg",
  pre_noc_index_ii_doc:"https://restdemo.growthifye.in/applicationservice/./uploads/morg_noc/135/documents_135_pre_noc_index_ii_doc_135prenocindexiidoc.jpg",
  pre_noc_receipt_doc:"https://restdemo.growthifye.in/applicationservice/./uploads/morg_noc/135/documents_135_pre_noc_receipt_doc_135prenocreceiptdoc.jpg",
  company_name:"ABC COMPANY PVT LTD",
  doc_company_certifiate: "company_cert.pdf",
    doc_memorandum_Association: "memorandum.pdf",
    doc_deed_of_partnership: "partnership_deed.pdf",
    doc_registration_certificate: "reg_certificate.pdf",
    doc_merger_nclt_order: "merger_order.pdf",
    doc_llp_agreement: "llp_agreement.pdf",
    doc_establishment_certificate: "establishment_cert.pdf",
    post_facto_approval: "approval.pdf",
    processing_fee: 25000,
    processing_fee_gst: 4500,
    assignment_charges: 35000,
    penalty: 10000,
    penalty_gst: 1800,
    terms_and_conditions: "I Agree to the terms and conditions.",
    sub_licensee_name: null,
    sub_licensee_aadhaar_no: "3456-7345-6786",
    sub_licensee_pan_no: "APQPG5535B",
    sub_licensee_company_type: "54",
    sub_licensee_company_name: "Trade & Business Promotion Agencies",
    stamp_duty_paid: "0"? 'no':'yes',
    stamp_duty_charges: 2500,
  };
  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    if (isSuccessResSummary) {
        setSummary(summaryData[0]);
       setPayment(summaryData?.PaymentDetails[0]); // Assuming summaryData is an array
       setFormData(summaryData[0]);
    }
}, [isSuccessResSummary, summaryData]);

const calculateTotalAmount = () => {
  let total = 64200.00; // Base amount without penalty
  if (formData.post_facto_approval === 'yes' ) {
    total += 11800.00; // Penalty + GST
  }
  if (formData.stamp_duty_paid === '0') {
    total += formData.processing_fee * 0.1; // Add 10% stamp duty
}
  return total.toFixed(2);
};


  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setConfirmDialogOpen(true);
    } else {
      setIsCheckboxChecked(false);
    }
  };

  const handleConfirmDialogClose = (confirm) => {
    setConfirmDialogOpen(false);
    if (confirm) {
      setIsCheckboxChecked(true);
    } else {
      setIsCheckboxChecked(false);
    }
  };
  const handleRadioChange = (event) => {
    setIsRadioChecked(event.target.value === "billdesk");
    setIsCheckboxChecked(false); // Reset checkbox when radio button changes
  };
  const {
    isFetchRegStepper,
    isSuccessgetRegStepper,
    getRegActiveStep,
    isErrorgetRegStepper,
    errorMessagegetRegStepper,
    isSuccessRegStepper
  } = useSelector(RegistrationStepperSelector);


  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    dispatch(getRegistrationProgress());
    dispatch(clearRegistrationStepperData());
  }, [dispatch, isSuccessRegStepper]);

  const steps = [
    {value:"Mortgage Details", label: "Details of the premises proposed to be mortgage" },
    {value:"Lessee Details", label: "Lessee Details" },
    { value:"Liaison Details",label: "Person authorized for Liaison" },
    { value:"OC/Part OC",label: "OC/Part OC of the premises to be assign" },
    { value:"BUA Detail",label: "Whether Premises is part of original BUA/ Addl. BUA (Sq. Mtrs.)" },
    {value:'Sub Licensee Detail', label: 'Sub Licensee Detail' },
    
    { value:"Previous NOC",label: "Details of the previous NOC granted (if Applicable)" },
    {value:"Uploads Docs", label: "Upload Documents" },
    {value:"Fees Detail", label: "Processing Fees Details" },
    {value:"Payment", label: "Payment" }
  ];

  useEffect(() => {
    if (isSuccessgetRegStepper) {
      setActiveStep(getRegActiveStep);
    }
  }, [isSuccessgetRegStepper, getRegActiveStep])
  const sectionRefs = useRef(steps.reduce((acc, step) => {
    acc[step.value] = React.createRef();
    return acc;
  }, {})).current;

  const scrollToSection = (value) => {
    sectionRefs[value].current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Container maxWidth="xl">
      <Card>
        <CardContent>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            align="center"
            sx={{
              fontWeight: "bold",
              mb: 1,
              background: "linear-gradient(126deg, #00a74a, #15489f)",
              color: "white",
              position: "sticky",
              top: "16px",
              zIndex: 1, // Ensures it stays above other content
            }}
          >
            Application Preview
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box margin={4}>
              {/* <HorizontalLinearStepper /> */}
              <ul className="stepper">
                {steps.map((step, index) => {
                  const isCurrent = index === activeStep - 1;
                  const isComplete = index < activeStep - 1;
                  return (
                    <Tooltip key={index} title={step.label} arrow>
                      <li
                        className={`stepper__item ${isCurrent ? 'current' : ''} ${isComplete ? 'complete' : ''}`}
                        onClick={() => scrollToSection(step.value)}
                      >
                        {step.value}
                      </li>
                    </Tooltip>
                  );
                })}
              </ul>

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}><Button  variant="contained" color="secondary"onClick={() => history.push("/registration")}>Edit Application </Button>
           </Box><FormCard>
            <Box margin={2} ref={sectionRefs["Mortgage Details"]}>
              <Grid item xs={12}>
                <GradientChip label="Mortgage Property Detail" />
              </Grid>

              <Grid
                container
                spacing={1}
                style={{ paddingInline: 20, marginTop: 5 }}
              >
                <Grid item xs={3}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Property Type:</strong> {formData?.premises_type}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Property Name:</strong> {formData.property_name}
                  </Typography>{" "}
                </Grid>

                <Grid item xs={3}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Block/Plot No.:</strong> {formData.pro_block_no }
                  </Typography>{" "}
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Property Code:</strong> {formData?.property_code }
                  </Typography>{" "}
                </Grid>
                <Grid item xs={2}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Unit No.:</strong> {formData.pro_unit_no}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={2}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Floor:</strong> {formData.pro_floor}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={2}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Wing:</strong> {formData.pro_wing}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={2}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Area Sq. Mtr.:</strong> {formData.property_area}
                  </Typography>{" "}
                </Grid>
              </Grid>
            </Box>

            {/* Lessee Details Section */}
            <Divider />
            <Box margin={2} ref={sectionRefs["Lessee Details"]}>
              <Grid item xs={12}>
                <GradientChip label="Lessee Detail" />
              </Grid>
              <Grid
                container
                spacing={1}
                style={{ paddingInline: 20, marginTop: 5 }}
              >
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Lessee:</strong> {formData.lessee_name}
                  </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>Sub-Lessee Mortgagor:</strong>{" "}
                  {formData.sub_lessee_name}
                </Typography></Grid>
                <Grid item xs={4}>   <Typography variant="body1">
                  <strong>Society Formed:</strong> {formData.lessee_society_form}
                </Typography>

                </Grid>
              </Grid>
            </Box>
            {/* Liaison Person Details Section */}
            <Divider />
            <Box margin={2} ref={sectionRefs["Liaison Details"]}>
              <Grid item xs={12}>
                <GradientChip label="Authorized Liaison Person" />
              </Grid>
              <Grid
                container
                spacing={1}
                style={{ paddingInline: 20, marginTop: 5 }}
              >
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Is Leesee ? :</strong> {formData.lessee}
                  </Typography></Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Person Name:</strong> {formData.auth_person_name}
                  </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>Designation:</strong> {formData.auth_person_designation}
                </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>Mobile No.:</strong> {formData.auth_person_mobile}
                </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>Aadhar No:</strong> {formData.auth_person_aadhar_no}
                </Typography></Grid>

                <Grid item xs={4}><Typography variant="body1">
                  <strong>PAN No:</strong> {formData.auth_person_pan_no}
                </Typography>

                </Grid>
              </Grid>
            </Box>
            {/* Bank Details Section */}
            <Divider sx={{ my: 3 }} />
            <Box margin={2} ref={sectionRefs["Sub Licence Detail"]}>
              <Grid item xs={12}>
                <GradientChip label="Sub Licence Detail" />
              </Grid>
              <Grid
                container
                spacing={1}
                style={{ paddingInline: 20, marginTop: 5 }}
              >
                <Grid item xs={4}>


                  <Typography variant="body1">
                    <strong>Sub Licensee Name:</strong> {formData.sub_licensee_name}
                  </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>Sub Licensee Aadhar No :</strong> {formData.sub_licensee_aadhaar_no}
                </Typography></Grid>
                <Grid item xs={4}> <Typography variant="body1">
                  <strong>Sub Licensee PAN No:</strong> {formData.sub_licensee_pan_no}
                </Typography></Grid>
                <Grid item xs={12}> <Typography variant="body1">
                  <strong>Company Type:</strong> {formData.sub_licensee_company_name}
                </Typography>


                </Grid>
              </Grid>
            </Box>
           
        
            <Divider />
            <Box margin={2} ref={sectionRefs["Previous NOC"]}>
              <Grid item xs={12}>
                <GradientChip label="Previous NOC Details" />
              </Grid>

              <Grid
                container
                spacing={1}
                style={{ paddingInline: 20, marginTop: 5 }}
              >


                <Grid item xs={3}>
                  <Typography variant="body1">
                    <strong>Letter No.:</strong>{" "}
                    {formData.pre_noc_letter_no}
                  </Typography></Grid>
                <Grid item xs={2}>   <Typography variant="body1">
                  <strong>NOC Date:</strong>{" "}
                  {formData.pre_noc_date}
                </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>Document Reg. Details:</strong>{" "}
                  {formData.pre_noc_doc_reg}
                </Typography></Grid>
                <Grid item xs={2}><Typography variant="body1">
                  <strong>Reg. No:</strong>{" "}
                  {formData.pre_noc_reg_no}
                </Typography>



                </Grid>
              </Grid>
            </Box>
            <Divider />
            
            <Box margin={2} ref={sectionRefs["Fees Detail"]}>
              <Grid item xs={12}>
                <GradientChip label="Processing Fee Detail" />
              </Grid>
            </Box>
            <Box margin={2}>
            <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Qty</StyledTableCell>
                                <StyledTableCell>Fee Type</StyledTableCell>
                                <StyledTableCell>Serial #</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>Subtotal</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell>1</StyledTableCell>
                                <StyledTableCell>Processing Fee</StyledTableCell>
                                <StyledTableCell>455-981-221</StyledTableCell>
                                <StyledTableCell>Processing Fee Details</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formData.processing_fee}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell>2</StyledTableCell>
                                <StyledTableCell>Processing Fee GST</StyledTableCell>
                                <StyledTableCell>455-981-222</StyledTableCell>
                                <StyledTableCell>Processing Fee - 18% GST</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formData.processing_fee_gst}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell>3</StyledTableCell>
                                <StyledTableCell>Assignment Charges</StyledTableCell>
                                <StyledTableCell>735-845-642</StyledTableCell>
                                <StyledTableCell>Stamp Duty 10% on Document Register Amount</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formData.assignment_charges}</StyledTableCell>
                            </StyledTableRow>
                            {formData.post_facto_approval === 'yes' && (
                                <>
                                    <StyledTableRow>
                                        <StyledTableCell>4</StyledTableCell>
                                        <StyledTableCell>Penalty</StyledTableCell>
                                        <StyledTableCell>422-568-642</StyledTableCell>
                                        <StyledTableCell>Post Facto Consent</StyledTableCell>
                                        <StyledTableCell className={classes.rightAlign}>₹ {formData.penalty}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell>5</StyledTableCell>
                                        <StyledTableCell>Penalty GST</StyledTableCell>
                                        <StyledTableCell>422-568-643</StyledTableCell>
                                        <StyledTableCell>Post Facto Consent - 18% GST</StyledTableCell>
                                        <StyledTableCell className={classes.rightAlign}>₹ {formData.penalty_gst}</StyledTableCell>
                                    </StyledTableRow>
                                </>
                            )}
                             {formData.stamp_duty_paid == '0' && (
                                <StyledTableRow>
                                    <StyledTableCell>6</StyledTableCell>
                                    <StyledTableCell>Stamp Duty</StyledTableCell>
                                    <StyledTableCell>122-568-643</StyledTableCell>
                                    <StyledTableCell>10% of Processing Fee as Stamp Duty</StyledTableCell>
                                    <StyledTableCell className={classes.rightAlign}>₹ {(formData.processing_fee * 0.1).toFixed(2)}</StyledTableCell>
                                </StyledTableRow>
                            )}
                            <StyledTableRow>
                                <StyledTableCell colSpan={4}>
                                    <Typography style={{ fontWeight: 600 }}>Total:</Typography>
                                </StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>
                                    <Typography style={{ fontWeight: 600 }}>₹ {calculateTotalAmount()}</Typography>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




            </Box>

            <Box mb={2} >
              <Grid container spacing={1} alignItems="center" style={{ paddingInline: 20, marginTop: 5 }}>
                <Grid item xs={3}>
                  <GradientChip label="Payment Methods" />
                </Grid>

                <Grid item xs={4}>

                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="payment-method"
                      name="paymentMethod"
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="billdesk"
                        control={<Radio />}
                        label={
                          <img
                            src={Billdesk}
                            alt="Billdesk"
                            style={{ width: "100px" }}
                          />
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  <Typography variant="body2" component="p" color="textSecondary">
                    *Note: Payment terms will be applicable.
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box mb={2} sx={{ display: "flex" }}>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckboxChecked}
                          onChange={handleCheckboxChange}
                          name="confirmCheckbox"
                          color="primary"
                          disabled={!isRadioChecked}
                        />
                      }
                      label="I agree to the terms and conditions"
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isCheckboxChecked}
                    >
                      Pay Now
                    </Button>
                  </Box>


                </Grid>

              </Grid>

            </Box>
            </FormCard>



          </form>
          <Dialog
            open={confirmDialogOpen}
            onClose={() => handleConfirmDialogClose(false)}
          >
            <DialogTitle>Confirm Payment</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to proceed with the payment? After payment,
                you will not be allowed to edit the application.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleConfirmDialogClose(false)}
                color="primary"
                variant="contained"
              >
                No
              </Button>
              <Button
                onClick={() => handleConfirmDialogClose(true)}
                color="primary"
                variant="contained"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>




        </CardContent>
      </Card>
    </Container>
  );
};

export default PaymentForm;


