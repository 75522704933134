// import React, { useEffect, useState } from 'react';
// import { Avatar, Box, Grid, Paper, Typography } from '@material-ui/core';
// import CircularWithValueLabel from './ProgressIndicator/CircularWithValueLabel';
// import Stepper from './Stepper/Stepper';
// import Applicationform from '../../../assets/SvgIcons/ApplicationForm.svg';
// import NewRegistration from '../../../assets/SvgIcons/Newregistration.svg';
// import RegistrationForm from './Registrationform/RegistrationForm';
// import { useDispatch, useSelector } from 'react-redux';
// import { applicantSelector } from '../../../redux/features/applicant/ApplicantSlice';
// import { personalDetailsFormStyles } from '../PersonalDetailsPageComponents/PersonalDetailsForm/PersonalDetailsForm.styles';
// import { RegistrationStepperSelector, clearRegistrationStepperData, cleargetRegistrationStepperData, getRegistrationProgress } from '../../../redux/features/registration/registrationStepperSlice';
// import { getSummaryDetails } from '../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
// import MortgageForm from './MortgageNocComponents/MortgageForm';
// import Background from "../../../assets/bg.png";
// export default function BidderRegistration() {
//   const classes = personalDetailsFormStyles();
//   const {
//     isFetchingApplicant,
//     isFetchingApplicantGet,
//     isSuccessResApplicantGet,
//     isSuccessResApplicant,
//     isErrorApplicant,
//     errorMessage,
//     applicantData,
//     isSuccessEmailVerificationOTP,
//     isErrorVerifyEmailVerificationOTP,
//     errorMsgVerifyEmailVerificationOTP,
//     isSuccessVerifyEmailVerificationOTP,
//     isFetchingVerifyEmailVerificationOTP,
//   } = useSelector(applicantSelector);

//   const dispatch = useDispatch();

//   const { isFetchRegStepper,
//     isSuccessgetRegStepper,
//     getRegStepper,
//     getRegActiveStep,
//     isErrorgetRegStepper,
//     getRegStepperData,
//     errorMessagegetRegStepper,
//     getRegTotalStep,
//     isSuccessRegStepper,
//   } = useSelector(RegistrationStepperSelector);
//   const [activeStep, setActiveStep] = useState(0);
//   const [totalSteps, setTotalSteps] = useState(10);



//   useEffect(() => {
//     dispatch(getRegistrationProgress());
//     dispatch(clearRegistrationStepperData())
//   }, [isSuccessRegStepper])

//   useEffect(() => {
//     if (isSuccessgetRegStepper || getRegActiveStep) {
//       setActiveStep(getRegActiveStep);
//       setTotalSteps(getRegTotalStep);
//     }
//   }, [isSuccessgetRegStepper, getRegActiveStep])

//   useEffect(() => {
//     dispatch(getSummaryDetails());
//   }, [dispatch]);
//   return (

//     <Grid container spacing={3} justifyContent='space-around'   >
//       {/* <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} style={{ maxWidth: '32%', overflow: 'hidden' }}> */}
     
//         {/* <Grid item xs={12}   >
//           <img src={NewRegistration} alt="New Registration Logo" /></Grid> */}
          
//           {/* <Grid item xs={8} justifyContent='center' alignItems='center'><Typography variant="h3"justifyContent='center' alignItems='center' style={{color:'white'}} fontWeight="700">
//             Application For Mortgage NOC
//           </Typography> 
//           </Grid>*/}
    
//       <Grid item xs={3} style={{ backgroundColor: "white" ,borderRadius:20,marginTop:5,}}  >

        



//           {/* <Grid item  >
//                 <img src={Applicationform} alt="Logo" />
//               </Grid>
//               <Grid item  >
//                 <Typography variant="h6" component="span" fontWeight="500">
//                 Application for Mortgage Noc
//                 </Typography>

//               </Grid> */}




//           <Stepper />






//       </Grid>

//       <Grid item xs={9}    style={{ backgroundColor: "white" ,borderRadius:20,marginTop:5,}}>


//         <MortgageForm section={activeStep} />






//       </Grid>
//     </Grid>


//   );
// }

// import React, { useEffect, useState } from 'react';
// import { Avatar, Box, Grid, Paper, Typography } from '@material-ui/core';
// import CircularWithValueLabel from './ProgressIndicator/CircularWithValueLabel';
// import Stepper from './Stepper/Stepper';
// import Applicationform from '../../../assets/SvgIcons/ApplicationForm.svg';
// import NewRegistration from '../../../assets/SvgIcons/Newregistration.svg';
// import RegistrationForm from './Registrationform/RegistrationForm';
// import { useDispatch, useSelector } from 'react-redux';
// import { applicantSelector } from '../../../redux/features/applicant/ApplicantSlice';
// import { personalDetailsFormStyles } from '../PersonalDetailsPageComponents/PersonalDetailsForm/PersonalDetailsForm.styles';
// import { RegistrationStepperSelector, clearRegistrationStepperData, cleargetRegistrationStepperData, getRegistrationProgress } from '../../../redux/features/registration/registrationStepperSlice';
// import { getSummaryDetails } from '../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
// import MortgageForm from './MortgageNocComponents/MortgageForm';
// import Background from "../../../assets/bg.png";

// export default function BidderRegistration() {
//   const classes = personalDetailsFormStyles();
//   const {
//     isFetchingApplicant,
//     isFetchingApplicantGet,
//     isSuccessResApplicantGet,
//     isSuccessResApplicant,
//     isErrorApplicant,
//     errorMessage,
//     applicantData,
//     isSuccessEmailVerificationOTP,
//     isErrorVerifyEmailVerificationOTP,
//     errorMsgVerifyEmailVerificationOTP,
//     isSuccessVerifyEmailVerificationOTP,
//     isFetchingVerifyEmailVerificationOTP,
//   } = useSelector(applicantSelector);

//   const dispatch = useDispatch();

//   const { isFetchRegStepper,
//     isSuccessgetRegStepper,
//     getRegStepper,
//     getRegActiveStep,
//     isErrorgetRegStepper,
//     getRegStepperData,
//     errorMessagegetRegStepper,
//     getRegTotalStep,
//     isSuccessRegStepper,
//   } = useSelector(RegistrationStepperSelector);
//   const [activeStep, setActiveStep] = useState(0);
//   const [totalSteps, setTotalSteps] = useState(10);

//   useEffect(() => {
//     dispatch(getRegistrationProgress());
//     dispatch(clearRegistrationStepperData())
//   }, [isSuccessRegStepper])

//   useEffect(() => {
//     if (isSuccessgetRegStepper || getRegActiveStep) {
//       setActiveStep(getRegActiveStep);
//       setTotalSteps(getRegTotalStep);
//     }
//   }, [isSuccessgetRegStepper, getRegActiveStep])

//   useEffect(() => {
//     dispatch(getSummaryDetails());
//   }, [dispatch]);

//   return (
//    <div style={{display:'flex', justifyContent:'space-between'}}>
//       <Grid item xs={12} md={3} style={{ backgroundColor: "white", borderRadius: 20, marginTop: 5 }}>
//         <Stepper />
//       </Grid>
//       <Grid item xs={12} md={9} style={{marginLeft:10,backgroundColor: "white", borderRadius: 20, marginTop: 5}} >
//        <MortgageForm section={activeStep} />
//       </Grid>
//       </div>
//   );
// }

import React, { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Paper, Typography } from '@material-ui/core';
import CircularWithValueLabel from './ProgressIndicator/CircularWithValueLabel';
import Stepper from './Stepper/Stepper';
import Applicationform from '../../../assets/SvgIcons/ApplicationForm.svg';
import NewRegistration from '../../../assets/SvgIcons/Newregistration.svg';
import RegistrationForm from './Registrationform/RegistrationForm';
import { useDispatch, useSelector } from 'react-redux';
import { applicantSelector } from '../../../redux/features/applicant/ApplicantSlice';
import { personalDetailsFormStyles } from '../PersonalDetailsPageComponents/PersonalDetailsForm/PersonalDetailsForm.styles';
import { RegistrationStepperSelector, clearRegistrationStepperData, cleargetRegistrationStepperData, getRegistrationProgress } from '../../../redux/features/registration/registrationStepperSlice';
import { getSummaryDetails } from '../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import MortgageForm from './MortgageNocComponents/MortgageForm';
import Background from "../../../assets/bg.png";

export default function BidderRegistration() {
  const classes = personalDetailsFormStyles();
  const {
    isFetchingApplicant,
    isFetchingApplicantGet,
    isSuccessResApplicantGet,
    isSuccessResApplicant,
    isErrorApplicant,
    errorMessage,
    applicantData,
    isSuccessEmailVerificationOTP,
    isErrorVerifyEmailVerificationOTP,
    errorMsgVerifyEmailVerificationOTP,
    isSuccessVerifyEmailVerificationOTP,
    isFetchingVerifyEmailVerificationOTP,
  } = useSelector(applicantSelector);

  const dispatch = useDispatch();

  const { isFetchRegStepper,
    isSuccessgetRegStepper,
    getRegStepper,
    getRegActiveStep,
    isErrorgetRegStepper,
    getRegStepperData,
    errorMessagegetRegStepper,
    getRegTotalStep,
    isSuccessRegStepper,
  } = useSelector(RegistrationStepperSelector);
  const [activeStep, setActiveStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(11);

  useEffect(() => {
    dispatch(getRegistrationProgress());
    dispatch(clearRegistrationStepperData())
  }, [isSuccessRegStepper])

  useEffect(() => {
    if (isSuccessgetRegStepper || getRegActiveStep) {
      setActiveStep(getRegActiveStep);
      setTotalSteps(getRegTotalStep);
    }
  }, [isSuccessgetRegStepper, getRegActiveStep])

  useEffect(() => {
    dispatch(getSummaryDetails());
  }, [dispatch]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Grid
        item
        xs={12}
        md={3}
        style={{
          backgroundColor: "white",
          borderRadius: 20,
          marginTop: 5,
          height: 'calc(100vh - 10px)', // Adjust as needed
          overflow: 'auto',
          // paddingRight: 10, // To avoid content being cut off
          scrollbarWidth: 'none', // For Firefox
          msOverflowStyle: 'none', // For Internet Explorer and Edge
        }}
      >
        <Stepper />
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        style={{
          marginLeft: 10,
          backgroundColor: "white",
          borderRadius: 20,
          marginTop: 5,
          height: 'calc(100vh - 10px)', // Adjust as needed
          overflow: 'auto',
          paddingRight: 10, // To avoid content being cut off
          scrollbarWidth: 'none', // For Firefox
          msOverflowStyle: 'none', // For Internet Explorer and Edge
        }}
      >
        <MortgageForm section={activeStep} />
      </Grid>
  
    </div>
  );
}
